/* debug=off  */
////Interactive Data Tables Formatting
//--------------------------------------------------

#bottomTabs {
  &.btn-toolbar {
    position: relative;
    //top: -22px;
    .dropdown-toolbar {
      padding-bottom: 10px;
      padding-top: 10px;
      margin-bottom: 0px;
      border-bottom: solid 1px rgba(0, 0, 0, 0.3);
      border-left: solid 1px rgba(0, 0, 0, 0.3);
      border-right: solid 1px rgba(0, 0, 0, 0.3);
      -moz-border-radius: 0px 0px 6px 6px;
      -webkit-border-radius: 0px 0px 6px 6px;
      border-radius: 0px 0px 6px 6px;
    }
  }
  &.btn-toolbar-centered {
    text-align: center;
    .btn-group {
      float: none !important;
      a span {
        text-align: left;
        display: block;
        font-size: 70%;
        filter: alpha(opacity = 60);
        opacity: 0.6;
      }
    }
  }
}

.region-header{
 h1 span, h2 span, h3 span, h4 span, h5 span, h6 span {
      display: block;
      font-weight: 100;
      font-size: 80%;
}
}
//.fa-search {
//padding-right: 0px;
//}
////.btn-action {
//color: #fff;
//background-color: #DC5F13;
//border-color: #DC5F13;
//}
////.deliniate-top, .deliniate-right, .deliniate-bottom {
//border-color: rgba(0, 0, 0, 0.15);
//border-style: solid;
//}
////.deliniate-left {
//border-color: rgba(0, 0, 0, 0.15);
//border-style: solid;
//border-top-width: 0px;
//border-right-width: 0px;
//border-bottom-width: 0px;
//border-left-width: 1px;
//}
////table.table tbody tr.label-new td {
//border-color: #5cb85c;
//}

.apps-bea-gov {
  .flex-row {
    display: flex;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  .card-icon {
    margin: 10px;
    padding: 20px !important;
    background-color: rgba(45, 51, 56, 0.02);
    border: solid 3px rgba(45, 51, 56, 0.5);
    border-radius: 0px;
    -webkit-box-shadow: 3px 3px 25px -10px rgba(0, 0, 0, 1);
    -moz-box-shadow: 3px 3px 25px -10px rgba(0, 0, 0, 1);
    box-shadow: 3px 3px 25px -10px rgba(0, 0, 0, 1);
    .media-object {
      border: solid 1px rgba(0, 0, 0, 0);
    }
    &:hover .media-object {
      border: solid 1px rgba(0, 0, 0, 0.5);
    }
    .media-body {
      padding-left: 15px;
      color: #666666;
      h3 {
        border-bottom: solid 1px rgba(45, 51, 56, 0.15);
        padding-bottom: 8px;
        margin-bottom: 8px;
        span {
          font-weight: 100;
          font-size: 60%;
        }
      }
    }
    &:hover {
      .media-body {
        h3 {
          color: rgba(255, 255, 255, 1);
          border-bottom: solid 1px rgba(255, 255, 255, 0.15);
        }
        color: rgba(255, 255, 255, 0.75);
      }
      border: solid 3px rgb(220, 95, 19);
      background-color: rgb(220, 95, 19);
      text-decoration: none;
    }
  }
}

.shadow {
  -moz-box-shadow: 8px 8px 14px #444 !important;
  -webkit-box-shadow: 3px 3px 3px 4px #444 !important;
  box-shadow: 8px 8px 14px rgba(0, 0, 0, 0.15) !important;
  -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#444444')" !important;
  filter: progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#444444') !important;
}

/* formatting for ESR Page */

.messageclass {}

body.bea-application.navbar-is-fixed-top {
  margin-top: 55px;
}

.bea-application {
v {
    &.navbar {
      .navbar-collapse {
        padding-left: 0px;
      }
      h1 {
        margin: 0px;
        padding: 0px;
        span {
          display: block;
          width: 100%;
          font-weight: 300;
          font-size: 80%;
          line-height: normal;
        }
        .caret {
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 2px;
          vertical-align: middle;
          border-top: 12px dashed #dc5f13;
          border-top: 12px solid \9;
          border-right: 12px solid transparent;
          border-left: 12px solid transparent;
        }
      }
      .container-fluid {
        padding-left: 0 !important;
      }
    }
    &.navbar-collapse {
      padding-left: 0 !important;
      &.in {
        padding-left: 30px;
      }
    }
  }
  &.main-container .region-header {
    padding-left: 15px;
    padding-right: 15px;
  }
  nav.sub-nav {
    .btn-default {
      color: $text-color;
      background-color: transparent;
      border-color: transparent;
      &:hover {
        color: $white;
        background-color: $brand-slate;
        border-color: transparent;
      }
    }
    .btn-warning {
      color: $white;
      background-color: $brand-orange;
      border-color: transparent;
    }
  }
  .dataTables_wrapper table {
    background-color: #ffffff;
  }
}

#bottomTabs {
  &.btn-toolbar .dropdown-toolbar {
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 0px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.3);
    border-left: none;
    border-right: none;
    border-radius: 0px;
    background-color: #dc6026;
  }
  &.btn-toolbar-centered {
    text-align: center;
    //float: none !important;
    a {
      margin-right: 4px;
      margin-top: 3px;
      margin-bottom: 3px;
      float: none;
      span {
        text-align: left;
        display: block;
        font-size: 75%;
        zoom: 1;
        filter: alpha(opacity = 60);
        opacity: 0.6;
      }
    }
  }
}

.panel-shadow {
  box-shadow: none;
}

.apps-bea-gov .bea-application {
  #xmlWraper {
    .row, [class*="col-"] {
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  .page-header {
    margin-bottom: 0px;
    border-bottom: none;
  }
  #loginLinks {
    float: right;
    .btn-group ul.dropdown-menu {
      border: solid 1px #398439;
      li a {}
    }
  }
  #app_name > span {
    xdisplay: inline-block;
    border-top: 1px solid #eeeeee;
    padding-top: 8px;
    margin-top: 8px;
  }
  .app-itables {
    #wraper {}
    .tab-pane {
      padding-left: 0px;
      padding-right: 0px;
      p {
        margin-left: 15px;
      }
    }
    .Table_Title {}
    .Sub_Title {
      color: rgba(0, 0, 0, 0.5);
    }
    .Table_Description {
      margin: auto auto 20px auto;
      font-size: 70%;
    }
    .list-group .list-group-item:hover {
      color: white;
      background-color: #337ab7;
    }
  }
  #Final_Table_footer {
    .footNoteTitle {
      padding: 5px 10px;
      color: #fff;
      background-color: #aaa;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      border-radius: 4px;
    }
    .FootNoteStyle {
      text-align: left;
      white-space: normal;
    }
  }
}

.bea-application #Final_Table_footer .NormalStyle {
  text-align: left;
  white-space: normal;
}

.apps-bea-gov .bea-application .FootNoteStyle {
  font-size: 90%;
  padding: 0px 0px 0px 16px;
  margin: 0px 10px 8px 10px;
  background-image: url(/_images/bullet-orange.png);
  background-repeat: no-repeat;
  background-position: left 6px;
}



//.fa-search {
//padding-right: 0px;
//}
////.btn-action {
//color: #fff;
//background-color: #DC5F13;
//border-color: #DC5F13;
//}
////.deliniate-top, .deliniate-right, .deliniate-bottom {
//border-color: rgba(0, 0, 0, 0.15);
//border-style: solid;
//}
////.deliniate-left {
//border-color: rgba(0, 0, 0, 0.15);
//border-style: solid;
//border-top-width: 0px;
//border-right-width: 0px;
//border-bottom-width: 0px;
//border-left-width: 1px;
//}
////table.table tbody tr.label-new td {
//border-color: #5cb85c;
//}

.apps-bea-gov {
  .flex-row {
    display: flex;
  }
  .flex-column {
    display: flex;
    flex-direction: column;
  }
  .card-icon {
    margin: 10px;
    padding: 20px !important;
    background-color: rgba(45, 51, 56, 0.02);
    border: solid 3px rgba(45, 51, 56, 0.5);
    border-radius: 0px;
    -webkit-box-shadow: 3px 3px 25px -10px rgba(0, 0, 0, 1);
    -moz-box-shadow: 3px 3px 25px -10px rgba(0, 0, 0, 1);
    box-shadow: 3px 3px 25px -10px rgba(0, 0, 0, 1);
    .media-object {
      border: solid 1px rgba(0, 0, 0, 0);
    }
    &:hover .media-object {
      border: solid 1px rgba(0, 0, 0, 0.5);
    }
    .media-body {
      padding-left: 15px;
      color: #666666;
      h3 {
        border-bottom: solid 1px rgba(45, 51, 56, 0.15);
        padding-bottom: 8px;
        margin-bottom: 8px;
        span {
          font-weight: 100;
          font-size: 60%;
        }
      }
    }
    &:hover {
      .media-body {
        h3 {
          color: rgba(255, 255, 255, 1);
          border-bottom: solid 1px rgba(255, 255, 255, 0.15);
        }
        color: rgba(255, 255, 255, 0.75);
      }
      border: solid 3px rgb(220, 95, 19);
      background-color: rgb(220, 95, 19);
      text-decoration: none;
    }
  }
}

//.shadow {
//-moz-box-shadow: 8px 8px 14px #444 !important;
//-webkit-box-shadow: 3px 3px 3px 4px #444 !important;
//box-shadow: 1px 1px 14px rgba(0, 0, 0, 0.15) !important;
//-ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#444444')" !important;
//filter: progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#444444') !important;
//}

/* formatting for ESR Page */


.messageclass {}

body.bea-application.navbar-is-fixed-top {
  margin-top: 55px;
}

.bea-application {
  nav {
    &.navbar {
      .navbar-collapse {
        padding-left: 0px;
      }
      h1 {
        margin: 0px;
        padding: 0px;
        span {
          display: block;
          width: 100%;
          font-weight: 300;
          font-size: 80%;
          line-height: normal;
        }
        .caret {
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 2px;
          vertical-align: middle;
          border-top: 12px dashed #dc5f13;
          border-top: 12px solid \9;
          border-right: 12px solid transparent;
          border-left: 12px solid transparent;
        }
      }
      .container-fluid {
        padding-left: 0 !important;
      }
    }
    &.navbar-collapse {
      padding-left: 0 !important;
      &.in {
        padding-left: 30px;
      }
    }
  }
  &.main-container {
    padding-left: 0px;
    padding-right: 0px;
    .region-header {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
//  nav.sub-nav {
//    .btn-default {
//      color: white;
//      background-color: transparent;
//      border-color: transparent;
//      &:hover {
//        color: rgba(0, 0, 0, 1);
//        background-color: white;
//        border-color: transparent;
//      }
//    }
//    .btn-warning {
//      color: black;
//      background-color: white;
//      border-color: transparent;
//    }
//  }
  .dataTables_wrapper table {
    background-color: #ffffff;
  }
}

/* New sub-navbar definition for itables */
/* ========================================================== */

#bottomTabs {
  &.btn-toolbar .dropdown-toolbar {
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 0px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.3);
    border-left: none;
    border-right: none;
    border-radius: 0px;
    background-color: $gray-lighter; //#dc6026;
  }
  &.btn-toolbar-centered {
    text-align: center;
    .btn-group {
      float: none !important;
      a span {
        text-align: left;
        display: block;
        font-size: 70%;
        zoom: 1;
        filter: alpha(opacity = 60);
        opacity: 0.6;
      }
    }
  }
}

.panel-shadow {
  box-shadow: none;
}

.apps-bea-gov .bea-application {
  .page-header {
    margin-bottom: 0px;
    border-bottom: none;
  }
  #loginLinks {
    float: right;
    .btn-group ul.dropdown-menu {
      border: solid 1px #398439;
      li a {}
    }
  }
  h1#app_name > span {
    xdisplay: inline-block;
    border-top: 1px solid #eeeeee;
    padding-top: 8px;
    margin-top: 8px;
  }
  .app-itables {
    #wraper {}
    .tab-pane {
      padding-left: 0px;
      padding-right: 0px;
      p {
        margin-left: 15px;
      }
    }
    .Table_Title {}
    .Sub_Title {
      color: rgba(0, 0, 0, 0.5);
    }
    .Table_Description {
      margin: auto auto 20px auto;
      font-size: 70%;
    }
    .list-group .list-group-item:hover {
      color: white;
      background-color: #337ab7;
    }
  }
  #Final_Table_footer {
    .footNoteTitle {
      padding: 5px 10px;
      color: #fff;
      background-color: #aaa;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      -o-border-radius: 4px;
      border-radius: 4px;
    }
    .FootNoteStyle {
      text-align: left;
      white-space: normal;
    }
  }
}

.bea-application #Final_Table_footer .NormalStyle {
  text-align: left;
  white-space: normal;
}

.apps-bea-gov .bea-application .FootNoteStyle {
  font-size: 90%;
  padding: 0px 0px 0px 16px;
  margin: 0px 10px 8px 10px;
  background-image: url(/_images/bullet-orange.png);
  background-repeat: no-repeat;
  background-position: left 6px;
}
