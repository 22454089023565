/* debug=off 
 * Survey nodes, views and references styling.
 */

/*
 * Individual Survey of Current Business nodes.
 */
.page-node-type-survey-of-business {
  p {
    margin-bottom: 0;
  }
}

/*
 * Survey of Current Business main view.
 */
.view-recent-survey-of-business {
  //Main View
  &.scb-landing {
    .view-header {
      h2 {
        margin-top: 0;
      }
    }
  }
  //Archive View.
  &.scb-archive {
    .col-sm-12 {
      padding-left: 0;
      padding-right: 0;
    }
    .col-sm-9 {
      .row {
        margin-left: 15px;
        margin-right: 15px;
        padding-left: 0;
        padding-right: 0;
      }
    }
    .col-sm-3 {
      padding-right: 0;
      label {
        visibility: hidden;
      }
    }
  }
}

/*
 * Individual Survey Page nodes.
 */
.page-node-type-survey-page {
  article {
    .row {
      .col-sm-10 {
        padding-left: 0;
      }
      .col-sm-2 {
        padding-right: 0;
        p {
          margin-top: 20px;
        }
      }
    }
  }
}
