/* debug=on 
 * Search form and results styles.
 */

//Search form in navbar.
#search-block-form{
	margin-top: 9px;
	margin-bottom: 0px;
  &.navbar-right {
  	margin-right: -38px;
	}
	input{
		&.form-control{
			font-size: $font-size-med-sm;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
			border: none;
			height: 31px;
		}
	}
	.btn-default {
		position: relative;
		left: -4px;
		height: 31px;
		margin-left: 0px;
		border: none;
    	color: $btn-action-color;
    	background-color: $btn-action-bg;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}
	.btn-default:hover {
    	color: $white;
    	background-color: $btn-action-border;
	}
  .glyphicon {
    top: 3px;
  }
}
//Collapsed form.
.navbar-collapse {
  .block {
    float: right;
  }
    &.in{
      .block {
        float: none;
      }
    }
  .form-group {
    width: 80%;
    display: inline-block;
  }
  .form-control {
    width: 100%;
  }
  @media #{$normal} {
    .form-group {
      width: inherit;
    }
  }
}
