/* debug=off 
 * Form styles.
 */

//Checkboxes / radio buttons.
.checkbox, .radio {
  //Remove top/bottom margins when in table context, because this is most
  //likely a tableselect checkbox element.
  table &.form-no-label {
    margin-bottom: 0;
    margin-top: 0;
  }
}

//Browsers do not recognize pseudo :after selectors, we must create a wrapper
//around the select element to style it properly..
//$see http://stackoverflow.com/q/21103542
.select-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  .form-inline & {
    width: auto;
  }
  .input-group & {
    display: table-cell;
    //Reset rounded corners
    &:first-child .form-control:first-child {
      @include border-left-radius($border-radius-base);
    }
    &:last-child .form-control:first-child {
      @include border-right-radius($border-radius-base);
    }
  }
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 1;
    padding-right: 2em;
    &::-ms-expand {
      opacity: 0;
    }
  }
  &:after {
    color: $brand-primary;
    content: '▼';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    margin-top: -.5em;
    padding-right: .5em;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 10;

    //Use a more stylish icon if the theme uses glyphicons.
    .has-glyphicons & {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\e114'; //.glyphicon-chevron-down
      display: inline-block;
      font-family: 'Glyphicons Halflings';
    }
    .has-error & {
      color: $state-danger-text;
    }
    .has-success & {
      color: $state-success-text;
    }
    .has-warning & {
      color: $state-warning-text;
    }
  }
}

//Use CSS/SVG image for required mark.
//@see https://www.drupal.org/node/2152217
//@see https://www.drupal.org/node/2274631
.form-required:after {
  background-image: url('../images/required.svg');
  background-size: 10px 7px;
  content: "";
  display: inline-block;
  line-height: 1;
  height: 7px;
  width: 10px;
}

//Form action buttons.
.form-actions {
  .btn, .btn-group {
    margin-right: 10px;
  }
  .btn-group {
    .btn {
      margin-right: 0;
    }
  }
}

//Exposed form vertical formatting in sidebar
.region-sidebar-second{
        .views-exposed-form{
        .control-label, .form-control{
            font-size: $font-size-med-sm;
        }
        .control-label{}
        .form-control {
            padding-top: 4px;
            padding-right: 4px;
            padding-bottom: 6px;
            padding-left: 8px;
        }
        .form-select{
            margin-bottom: 15px;
        }
        
    }
}

//Meet the researcher form formatting
.meet-the-researchers{
  .view-display-id-block_1{
    margin-top: 45px;
    background-color: $gray-lighter;
    text-align: center;
    border-radius: 0px $border-radius-large 0px 0px;
    border: solid 1px $gray-light-table;
    //box-shadow: $card-shadow-color $card-shadow-dimensions;
    .view-header span a, .view-content a{
      font-size: $font-size-large;
      display: inline-flex;
      padding: 10px 15px;
      margin: 3px;
      color: $gray-dark;
      border-radius: $border-radius-base;
      border-color: $gray-light-table;
      &:hover{
        background-color: $brand-slate;
        color: $white;
      }
    }
    .view-header{
      position: relative;
      span{
        &.starter{
          position:absolute;
          top: -31px;
          left: -1px;
          border-color: $gray-light-table;
          border-style: solid;
          border-width: 1px 1px 0px 1px;
          border-radius: $border-radius-base $border-radius-base 0px 0px;
          //float: left;
          background-color: $gray-lighter;
          padding: 4px 12px;
        }
        &.ender{
          float: right;
        }
      }
    }
    .view-content{
      a{
        &.is-active{
          background-color: $brand-orange;
          color: $white;
        }
      }
    }
  }
  .views-exposed-form{
    .panel{
      border: solid 1px $gray-light-table;
      border-radius: 0px 0px $border-radius-large $border-radius-large;
      border-width: 0px 1px 1px 1px;
      background-color: transparent;
      box-shadow: none;
//      &:before{
//        content: "Filter"
//      }
    }
    .panel-body{
      position: relative;
     .control-label, .form-control{
        float:left;
        width: auto;
        margin-right: 5px;
        }
        .control-label{
          margin-top: 5px;
        }
      .button{
        margin-top: 2px;
      }
    }
  }
}

//Inline form formatting - News release archive
.view-release-archive{
  form{
    .form-control{
      margin-bottom: 10px;
    }
    .control-label{
      color: darken($gray-light, 2%);
      margin-bottom: 3px;
      margin-left: 3px;
      font-size: $font-size-med-sm;
    }
    .form-actions{
      text-align: right;
      .btn{
        &.btn-info{
          float: right;
        }
      }
    }
  }
}