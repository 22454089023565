/* debug=off  */
////Blog Formatting
//--------------------------------------------------
//Base styles 
//-------------------------
.article-full{
  .date-published{
    margin-bottom: 20px;
    color: $gray-light;
  }
}
