/* debug=off 
* Home page styling.
*/
//Home-page feature
.path-frontpage {
  .region-header{
    //display: none;
    .page-header {
      margin: 0px;
      border: none;
      h1{
        font-size: 1px;
        color: transparent;
        margin:0px;
        padding: 0px;
      }
    }
  }
  .page-header {
    margin: 0px;
  }
  .views-element-container{
    &.form-group{
      margin-bottom: 0px;
    }
  }
  .view-home-page-feature{
    width: 80%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    h2 {
      font-size: $font-size-lead;
      margin-top: 4px;
      margin-bottom: 6px;
      padding-bottom: 10px;
    }
    .feature-date{
      font-weight: $font-weight-light;
      margin: 0px auto 0px auto;
      padding: 0px;
      font-size: $font-size-med-sm;
      color: darken($gray-light, 2%);
    }
    h3 {
      font-size: $font-size-large;
      font-weight: $font-weight-heavy;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  .primary-economic-indicators {
    &.col-md-8 {
      padding-left: 0;
      .pei-icon {
        color: transparent;
        font-size: 0;
      }
    }
  }
  .block-beagovtwitterfeed {
    &.col-md-4 {
      @media #{$sub-tablet} {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .block-noteworthy {
    &.col-md-4 {
      padding-right: 0;
      @media #{$sub-tablet} {
        padding-left: 0;
      }
      .field--name-field-link {
        list-style-type: disc;
        padding-left: 19px;
        .field--item {
          display: list-item;
          list-style-type: disc;
        }
      }
    }
  }
  .homepage-nav-block {
    padding-left: 0;
    .nav {
      li {
        display: inline-block;
        list-style-image: none;
        list-style-type: none;
        a {
          padding: 6px 12px;
        }
      }
    }
  }
  .bea-wire-title-block {
    &.col-md-12 {
      padding: 0;
    }
  }
  .block-views-blockarticle-list-block-1 {
    &.col-md-8 {
      .form-group {
        padding-right: 25px;
      }
      .view-footer {
        margin-left: -25px;
      }
    }
  }
  .icon-block {
    .img-responsive {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      border: 6px solid rgba(0, 0, 0, 0);
      }
      a {
        &:hover .img-responsive {
          border: 6px solid $brand-orange;
        }
        h4 {
        font-size: 130%;
        color: #2375ae;
        }
        span {
        display: block;
        font-weight: normal;
        font-size: 70%;
        }
      }
    }
  //.views-row should be styled the same as .row to account for extra padding
  //on nested columns. The following is inspired by docroot/themes/custom/↵
  //beagov_theme/bootstrap/assets/stylesheets/bootstrap/_grid.scss
  .views-row {
    @include make-row;
  }
}
