/* debug=off 
 * Navigation elements styling.
 */
//.menu{
//&.nav:not(.nav-pills):not(.navbar-nav){
//li{
//border-left: solid 3px transparent; 
//border-bottom: solid 1px transparent; 
//border-top-right-radius: 4px;
//background-color: transparent;
//border-left: solid 3px  transparent; 
//border-bottom: solid 1px  transparent; 
//&:hover{
//background-color: $gray-lighter;
//border-left: solid 3px $gray-light; 
//border-bottom: solid 1px $gray-light; 
//}        
//&.active{
//background-color: $nav-active-highlight;
//border-left: solid 3px $nav-active-highlight-border; 
//border-bottom: solid 1px $nav-active-highlight-border; 
////background-image:url(/sites/default/files/corner-tag-primary.png);
////background-repeat: no-repeat;
////background-position: -38px 25px;
////a{
////color: $white-opacity-75;
////}
//}
//}
//}
//}