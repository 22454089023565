/* debug=on 
 * Author content type and related components styling.
 */


.page-node-type-author {
  /* Researcher Card =================================================== */
  article{
    &.author{
      //margin-top: -25px;
    }
  }
  .region-header {
    .breadcrumb{
      margin-bottom: 0px;
    }      
    //position: absolute;
  }
  .page-header {
    //h1{
    display: none;
    //}      
    //position: absolute;
  }

  .card-researcher {
    background-color: transparent;
    border: solid 1px $white;
    //border-bottom: solid 1px rgba(79, 79, 79, 0.25);
    border-radius: 0;
    margin-bottom: 15;
    padding-bottom: 0;
    margin-top: 0px;
    box-shadow: none;
    .panel-body {
      padding: 0px; //15px 0 0 0;
      position: relative;

      .card-details {
        @media #{$sub-tablet}{
          margin: 10px 0px;
        }         
        @media only screen and (min-width : 768px) {
          margin-top: 15px;
        }
        @media only screen and (min-width : 992px) {

          margin-top: 40px;
        }
        @media only screen and (min-width : 1200px) {

          margin-top: 55px;
        }        
      }
      .card-photo {
        height: auto;
        width: 20%;
        float: left;
        display: inline-block;
        @media #{$mobile} {
          display: none;

          //display: inline-table;
          //position: absolute;
          //bottom: 0;
        }
        @media #{$mobile-xs} {
          //width: 100%;
          //position: relative;
          //display: block;
        }
        > div {
          vertical-align: bottom;
        }
        img {
          border: 0;
          vertical-align: bottom;
        }
        img.img-circle {
          border: 1px solid rgba(0, 0, 0, 0.1);
          display: block;
          height: auto;
          max-width: 100%;
        }
      }
      .card-area {
        left: 2px;
        margin-bottom: 0;
        position: relative;
        top: 8px;
      }
      .card-name {
        font-size: 280%;
        font-weight: bold;
        margin-bottom: 0;
        padding-bottom: 0;
        //position: relative;
        //white-space: nowrap;
        line-height: 125%;
        @media #{$sub-tablet}{
          font-size: $font-size-h1;
          margin-top: 5px;
        }         
      }
    }
  }
  .card-contact {
    //text-align: left;
    margin-top: 0px;
    //margin-left: 10px;
    margin-bottom: 4px;
    //float: left;
    //position: relative;
    //right: -20px;
    a:first-of-type {
      //margin-right: 20px;
    }
    @media #{$mobile} {
//      text-align: left;
//      margin-left: 0px;
//      margin-bottom: 4px;
//      position: relative;
//      right: auto;
//      left: -15px;
    }    
    
  }  
  .nav.nav-tabs, .tab-content{
    &:not(.tabs--primary){
      position: relative;
      top: -69px;
      @media #{$mobile} {
        top: -20px;
      }    
    }
  }
  
  .tab-content{
    .tab-pane{
      @media #{$mobile} {
        padding-left: 0px;
        padding-right: 0px;
      }    
    }
    .pull-right{
      @media #{$mobile} {
        width: 100%;
        float: none !important;
      }    
    }
  }
  
  p.researcher-education, p.researcher-interest {
    //text-align: right;
  }
}

.view-meet-the-researchers{
  h3{
    border-bottom: solid 1px $card-default-border;
    margin-bottom: 25px;
  }
}

.paragraph--type--author-education-chunk,
.paragraph--type--author-prof-exp-chunk,
.paragraph--type--author-presentations-chunk,
.paragraph--type--author-activities-chunk{
  padding-bottom: 12px;
  padding-bottom: 12px;
  clear:both;
}
.paragraph--type--author-education-chunk{
  .field{
    float: left;
    &:not(:last-of-type){
      &:after{
        content: ",\00a0";
      }
    }
  }
}

.paragraph--type--author-prof-exp-chunk{
  .field--name-field-author-job-title{
    
  }
  .date-group{
    color: $gray;
    .field--name-field-author-job-location{
      padding-bottom: 12px;
      float: left;
      font-style: italic;
    }
    .field--name-field-author-job-year-start{
      float: left;
      &:before{
        content: "\00a0(";
      }      
      
      &:after{
        content: "\2013";
      }      
    }
    .field--name-field-author-job-year-stop{
      &:after{
        content: ")";
      }  
    }
  }
}

.paragraph--type--author-presentations-chunk{
  .field--name-field-author-presentations-place{
    float: left;
  }
  .field--name-field-author-presentations-place + .field--name-field-author-presentations-topic{
    &:before{
      //clear: both;
      content: "\A"; 
      white-space: pre; /* or pre-wrap */
    }      

  }
  
  .field--name-field-author-presentations-date{
    &:before{
      content: ",\00a0"; 
    }      
  }
  .field--name-field-author-presentations-topic{
    //float: left;
    color: $gray;
    font-style: italic;
  }
}

.paragraph--type--author-activities-chunk{
  
  .field--name-field-author-activity-topic{
    //float:left;
    //padding-bottom: 12px;
  }
  .date-group{
    
    //float: left;
    color: $gray;
    font-style: italic;
      &:after{
        content: "\00a0";
      }
    .field--name-field-author-activity-location{
      float: left;
      //&:before{
      //  content: ", ";
      //}
      //&:after{
      //  content: "\00a0"; 
      //}
    }
    
    .field--name-field-author-activity-location + .field--name-field-author-activity-year{
      &:before{
        content: ", ";
      }
    }
    .field--name-field-author-activity-year{
      float: left;      
    }
    
    .field--name-field-author-activity-year-end{
      float: left;
      &:before{
        content: "\2013";
      }
    }
  }

}