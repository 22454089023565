/* debug=off  */
////Lists
//Lists
ul.list-group li.active a {
  color: #fff;
}

.panel ul.list-contact:last-of-type,
.card ul.list-contact {
  padding-bottom: 0;
  margin-bottom: 3px;
}

ul {
  li.title {
    position: relative;
    left: -1em;
    list-style: none;
  }
}

//Contact list 
ul.list-contact {
  margin-left: 0;
  padding-left: 0;
  padding-bottom: 15px;
  li {
    list-style: none;
    margin-bottom: 15px;
  }
  .list-contact-title,
  .list-contact-name {
    border-bottom: solid 1px rgba(0,0,0,0.1);
    font-weight: bold;
    margin-bottom: 5px;
    padding-bottom: 4px;
    font-size: 120%;
  }
  .list-contact-area{
    color: $gray-light;
    font-size: 80%;
  }
  .list-contact-phone,
  .list-contact-email {
    text-align: right;
  }
}

//Blog Lists
.blog-list {
article {
margin-bottom: 25px;
img {
margin: 1px 20px 5px 0;
padding: 0;
width: 45%;
height: auto;
float: left;
}
header {
a {
font-size: 160%;
font-weight: bold;
}
}
h4 {
margin-top: 3px;
}
footer {
padding-top: 0;
background-color: transparent;
margin-top: 0;
font-size: 100%;
}
}
}

//Accordion list group
#accordion{
  .list-group{
    margin: 0px;
    padding: 0px;
    .list-group-item{
      border-width: 1px 0px 0px 0px;
      a ~ small:not(.mime-type), span.small{
        display: block;
      }
    }  
    .list-group-item:first-of-type{
    border-top: 0px;
    } 
  }
}

//List directly within panel
.panel{
.list-group{
.list-group-item{
border-color: rgba($list-group-border, 0.50);
}
}
}

//Current Releases List
.page-current-releases{
.release-row{
//.release-name{}
.date-published{
color: $gray-light;
}
}
}

//Release Schedule list (table)
.page-scheduled-releases{
.release-row{
.scheduled-date{}
}
}


//News Release Page Lists
.page-node-type-release{
  .tab-pane .table ul {
    margin-bottom: 0px;
    margin-left: 5px;
    padding: 0px;
    li{
      display: block;
    }
  }
}