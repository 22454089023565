/* debug=off  */
//Breadcrumb elements
.breadcrumb {
  padding: 0px $breadcrumb-padding-horizontal;
  margin-bottom: 10px;
  margin-top: 10px;
  list-style: none;
  background-color: transparent;
  border-radius: $border-radius-base;
  font-size: $font-size-small;
  > li {
    display: inline-block;
    + li:before {
      //[converter] Workaround for https://github.com/sass/libsass/issues/1115
      $nbsp: "\00a0";
      content: "#{$breadcrumb-separator}#{$nbsp}"; //Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: $breadcrumb-color;
    }
  }
  > .active {
    color: darken($breadcrumb-active-color, 5%);
  }
}
