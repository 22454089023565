/* debug=on  */
//==================================================================//
//Styles for standard .gov 'banner' at top of site.
//https://designsystem.digital.gov/page-templates/landing/
//==================================================================//
.usa-banner {
  height: $govbar-height;
  background-color: #f1f1f1;
  border-bottom: solid 1px rgb(0, 81, 137);
  .usa-banner-header {
    padding-top: 2px;
    padding-bottom: 3px;
    font-size: $font-size-x-small;
    font-weight: $font-weight-normal;
    color: #606F7B; //$gray-light //-was original.
    @media #{$sub-tablet} {
      .usa-banner-inner{
        margin-left: -20px;
      }
    }
    p {
      float: left;      
      padding-top: 2px;
      margin-top: 0px;
      margin-bottom: 0px;
      overflow: hidden;
      vertical-align: middle;
    }
    img {
      float: left;
      margin-right: 1rem;
      margin-top: 0px;
      width: 20px;
      height: auto;
    }
  }
}