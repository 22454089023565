/* debug=off 
* Release content type styling.
*/
h2.panel-title {
  font-size: 170%;
}
.locator-beacon{
  margin: 0px;
}
.card-news-release{
  h2.panel-title {
    font-size: 100%;
  }
  h3 {
    margin-top: 0px;
    font-weight: normal;
    font-size: 140%;
  }	
  p{
    padding: 10px 0px;
  }
}
.page-node-type-release {
  .btn-product-view{
    margin-bottom:5px;
  }
  .tab-pane{
    //code, kbd, pre, samp {}        
    .release-embargo{
      color: $gray-light;
      > div:last-of-type{
        text-align: right;
      }
    }
    h1{
      text-align: center;
      font-size: $font-size-h2;
      margin: 25px auto;
      .lead{
      margin-top: 8px;
      }
    }
    h2{
      text-align: left;
      margin-top: 35px;
      margin-bottom: 10px;
      font-size: $font-size-h3;
      &:first-of-type{
        margin-top: 0px;
      }        
    }
    h3{
      font-size: $font-size-h4;
    }
    > ul{ //Remove margin from nested UL's
      margin-bottom: 25px;
    }
    .panel{
      margin: 35px;
      @media (max-width: $screen-md-min) { 
        margin: 35px 0px;
      }
    }
    figure{
      margin: 35px auto 25px auto;
      text-align: center;
      img{
        //.chart{
          width: 60%;
          height: auto;
        //}
      }
      figcaption{
        margin: 10px auto;
        font-size: $font-size-med-sm;
        color: $gray-light;
      }
    }
    img{
      &.embedded-entity{
        width: 75%;
        height: auto;
      }
    }
    pre + figure, pre + img{
      &.embedded-entity{
        text-align: left;
        width: 600px;
        height: auto;
        margin: 0px;
      }
    }
  }
}

//NEWS Landing Page Styling
.path-news {
h1 {
&.page-header {
margin-top: 20px;
}
}
.view-release-archive {
.row {
margin-left: 0;
margin-right: 0;
.col-md-12 {
padding-left: 0;
padding-right: 0;
.row {
margin-left: 0;
margin-right: 0;
}
}
}
.col-md-8 {
padding-left: 0;
}
.col-md-4 {
padding-right: 0;
}
}
.table-hover {
tbody {
tr:hover {
background-color: $white;
}
}
}
.table-striped {
tbody {
tr:nth-of-type(odd) {
background-color: $white;
}
}
}
  h2{
    &.scheduled-releases{
      margin-bottom: 0px;
      margin-top: 30px;
    }
  }
}