/* debug=off 
 * Node styling.
 */

.node-preview-container {
  margin-top: -($grid-gutter-width / 2);
}

.node-preview-form-select {
  padding: ($grid-gutter-width / 2);
}

//Embedded entities in textareas
.field .embedded-entity {
  width: auto;
  display: table;
  padding: 10px;
  figcaption {
    display: block;
    text-align: center;
    clear: both;
  }
  &.align-center {
    margin: 0 auto;
  }
}
