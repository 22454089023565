/* debug=on  */
//LSD Color Reference
//.brand-primary{
//  background-color: $brand-primary;
//  color: $brand-primary;
//}
//.brand-secondary{
//  background-color: $brand-secondary;
//  color: $brand-secondary;
//}
//.brand-success{
//  background-color: $brand-success;
//  color: $brand-success;
//}
//.brand-info{
//  background-color: $brand-info;
//  color: $brand-info;
//}
//.brand-warning{
//  background-color: $brand-warning;
//  color: $brand-warning;
//}
//.brand-danger{
//  background-color: $brand-danger;
//  color: $brand-danger;
//}
//.brand-orange{
//  background-color: $brand-orange;
//  color: $brand-orange;
//}
//.brand-slate{
//  background-color: $brand-slate;
//  color: $brand-slate;
//}
//.brand-slate-light{
//  background-color: $brand-slate-light;
//  color: $brand-slate-light;
//}
////Globals
html{
    background-color: $brand-primary;
}
* {
font-variant-ligatures: none;
}

#block-sitewidealert{
  //display: none;
}

//508-Tweaks
#block-beagov-theme-main-menu-menu{
  color: $white;
}

.user-logged-in{
  .is-unpublished:before, .is-published:before{
    display: block;
    //position: relative;
    //top: -90px;
    color: white;
    padding: 3px 8px;
    margin-bottom: 15px;
    border-radius: 3px;
  }
  .is-unpublished:before {
    content: "DRAFT";
    background-color: red;
  }
  .is-published:before {
    content: "PUBLISHED";
    background-color: green;
  }  
}

span.ext{
  background-image: url(/sites/default/files/extlink_s.png);
  footer & {
    background-image: url(/sites/default/files/extlink_s_white.png);
  }
}


//Tools
.full-height-columns{
        display: flex;
}
.vertical-align {
    display: flex;
    align-items: center;
}

.no-wrap{
        white-space:nowrap;
}
//Body
body {
  color: $gray-dark;
  font-family: $font-family-base;
}

//Headings
h1, h2, h3, h4, h5, h6 {
  //clear: both;
  color: $headings-color;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  margin-bottom: 0.5em;
  margin-top: 20px;
}
h1, h2, h3, h4, h5, h6 {
    span{
      font-weight: $font-weight-lightest;
    }
}
.h1 .small, .h1 small, h1 .small, h1 small,
.h2 .small, .h2 small, h2 .small, h2 small,
.h3 .small, .h3 small, h3 .small, h3 small,
.h4 .small, .h4 small, h4 .small, h4 small,
.h5 .small, .h5 small, h5 .small, h5 small,
.h6 .small, .h6 small, h6 .small, h6 small {
  color: $gray-light;
  font-size: 65%;
  font-weight: 400;
  line-height: 1;
}

article{
  h2{
    &:first-of-type{
     margin-top: 0px;
    }
  }
}

li .small, li small {
  color: $gray-light;
}
.page-header{
    padding-bottom: 0px;
    margin: 15px 0px 20px 0px;
    border-bottom: 1px solid #eeeeee;
    h1{
        margin-top: 0px;
        margin-bottom: 10px;
    }
}

.small, small {
  font-size: 85%;
}

.js-form-submit{
        padding-top: 3px !important;
}
        
//Bullets
ul{
  //adjusting sitewide bullet indentation: normal, nested, and inline ul button groups.
  padding-inline-start: 20px;
  li &{
    margin-top: 2px;
    padding-inline-start: 4px;
  }
  &.btn-group{
    padding-inline-start: 0px;
  }
  &:not(.nav):not(.menu):not(.list-group):not(.list-contact):not(.pager):not(.pagination):not(.toolbar-menu):not(.contextual-links):not(.footer-links):not(.entity-moderation-form){
    &.list-inline{
      li{
        &:first-of-type{
         @media #{$tablet}{
            padding-left: 0px;
            background-image: none;
          } 
        }
      }
    }
    li{
          padding-left: 16px;
          padding-bottom: 2px;
          list-style-type: none;
          background-image:url(/sites/default/files/bullet-orange.png);
          background-repeat: no-repeat;
          background-position: left .5em;
        }
    }
}

.form-group:last-child{
  margin-bottom: 0;
}

//.form-group:last-child,
//.panel:last-child {
//margin-bottom: 0;
//}


//Links
a {
  color: $link-color;
}
a:hover, a.focus, a:hover:focus {
  color: $link-hover-color;
}


////Region Help
.region-help {
  > .glyphicon {
    font-size: $font-size-large;
    float: left;
    margin: -0.05em 0.5em 0 0;
  }
  .block {
    overflow: hidden;
  }
}
.help-block, .control-group .help-inline {
  color: $gray-light;
  font-size: $font-size-small;
  margin: 5px 0 10px;
  padding: 0;
  &:first-child {
    margin-top: 0;
  }
}
//Displaying of MIME Type (The type of file a link is pointing to)
.mime-type,
.file-type,
.file_size{
	color: $link-color;
	font-size: $font-size-x-small;
	opacity: 0.50;
	vertical-align: top;
	//text-transform: uppercase;
	margin-left: 0px;
	font-weight: normal;
		}	
//lead class. this is 21 px in bootstrap.min.css used by prototype, but 24px in _type.scss used on our site.

.lead {
  font-size: $font-size-lead;
    font-weight: $font-weight-light;
}

//Updating .row to match bootstrap defaults from prototype.
//Currently pulling from grid.scss
.row {
  margin-left: -15px;
  margin-right: -15px;
}

//Changing tab spacing underneath navigation tab bars.
.region-content nav.tabs {
  margin-bottom: 15px;
}


.region-sidebar-second{
        font-size: 90%;
}

//Changing default label overides.
body.path-user, body.path-node {
  .js-form-type-item > h4.label {
    padding: 0;
    font-size: inherit;
    color: $headings-color;
    font-weight: normal;
  }
}

//Global ROW modifier - provides vertical divider line on last column within .row
.deliniate-column-right > [class^="col-"]:last-of-type{
    border-left: solid 1px rgba(0,0,0,0.1);
}

//Global ROW modifier - provides vertical divider line on first column within .row
.deliniate-column-left > [class^="col-"]:first-of-type{
    border-right: solid 1px rgba(0,0,0,0.1);
}

.row.deliniate-bottom{
    border-bottom: solid 1px rgba(0,0,0,0.1);
  padding-bottom: 15px;
  margin-bottom: 15px;
  }

//Regional App adjustment until the app can be updated
#sidebar{
        background-color: transparent;
        color: $headings-color;
}