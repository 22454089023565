/* debug=off 
* Tabs and local action styles.
*/
.local-actions {
  margin: 10px 0 10px -5px;
}
.tabs--secondary {
  margin: 10px 0 5px;
}

/*
* Missing Bootstrap 2 tab styling.
* $see http://stackoverflow.com/questions/18432577/stacked-tabs-in-bootstrap-3
* $see http://bootply.com/74926
*/
.tabbable {
  margin-bottom: 20px;
}
.tabs-below, .tabs-left, .tabs-right {
  > .nav-tabs {
    border-bottom: 0;
    .summary {
      color: $nav-disabled-link-color;
      font-size: $font-size-small;
    }
  }
}
.tab-pane > .panel-heading {
  display: none;
}
.tab-content > .active {
  display: block;
}

//Below.
.tabs-below {
  > .nav-tabs {
    border-top: 1px solid $nav-tabs-border-color;
    > li {
      margin-top: -1px;
      margin-bottom: 0;
      > a {
        border-radius: 0 0 $border-radius-base $border-radius-base;
        &:hover,
          &:focus {
            border-top-color: $nav-tabs-border-color;
            border-bottom-color: transparent;
          }
        }
      }
      > .active {
        > a,
          > a:hover,
            > a:focus {
              border-color: transparent $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color;
            }
          }
        }
      }

//Left and right tabs.
.tabs-left,
  .tabs-right {
    .tabs-left, .tabs-right {
      border-bottom: 0;
      padding-top: 2px;
      > li {
        float: none;
        margin-bottom: 2px;
      }
    }
    > .nav-tabs {
      padding-bottom: 20px;
      width: 220px;
      > li {
        float: none;
        &:focus {
          outline: 0;
        }
        > a {
          margin-right: 0;
          margin-bottom: 3px;
          &:focus {
            outline: 0;
          }
        }
      }
    }
    > .tab-content {
      border-radius: 0 $border-radius-base $border-radius-base $border-radius-base;
      border: 1px solid $nav-tabs-border-color;
      box-shadow: 0 1px 1px $white-opacity-05;
      overflow: hidden;
      padding: 10px 15px;
    }
  }

//Left tabs.
.tabs-left {
  border-right: 1px solid #ddd;
  > li {
    float: none;
    margin-bottom: 2px;
    margin-right: -1px;
    > a {
      border-radius: 4px 0 0 4px;
      margin-right: 0;
      display: block;
    }
  }
  > li.active {
    > a, > a:hover, > a:focus {
      border-bottom-color: #ddd;
      border-right-color: transparent;
    }
  }
  > .nav-tabs {
    float: left;
    margin-right: -1px;
    > li > a {
      border-radius: $border-radius-base 0 0 $border-radius-base;
      &:hover,
        &:focus {
          border-color: $nav-tabs-link-hover-border-color $nav-tabs-border-color $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color;
        }
      }
      > .active > a,
        > .active > a:hover,
          > .active > a:focus {
            border-color: $nav-tabs-border-color transparent $nav-tabs-border-color $nav-tabs-border-color;
            box-shadow: -1px 1px 1px $white-opacity-05;
          }
        }
      }

//Right tabs.
.tabs-right {
  border-left: 1px solid #ddd;
  > li {
    margin-left: -1px;
    > a {
      border-radius: 0 4px 4px 0;
      margin-right: 0;
    }
  }
  > li.active {
    > a, > a:hover, > a:focus {
      border-bottom: 1px solid #ddd;
      border-left-color: transparent;
    }
  }
  > .nav-tabs {
    float: right;
    margin-left: -1px;
    > li > a {
      border-radius: 0 $border-radius-base $border-radius-base 0;
      &:hover,
        &:focus {
          border-color: $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-link-hover-border-color $nav-tabs-border-color;
          box-shadow: 1px 1px 1px $white-opacity-05;
        }
      }
      > .active > a,
        > .active > a:hover,
          > .active > a:focus {
            border-color: $nav-tabs-border-color $nav-tabs-border-color $nav-tabs-border-color transparent;
          }
        }
      }


      .tab-pane {
        padding: 15px 25px;
        .page-node-type-release &{
          padding:25px;

        }
      }
      .faq .tab-pane {
        padding-top: 0;
        .row {
          padding-bottom: 15px;
        }
      }

      .sideways {
        margin-top: 50px;
        border: 0;
        position: relative;
        > li {
          height: 20px;
          width: 120px;
          margin-bottom: 100px;
          > a {
            border-bottom: 1px solid #ddd;
            border-right-color: transparent;
            text-align: center;
            border-radius: 4px 4px 0 0;
          }
        }
        > li.active {
          > a, > a:hover, > a:focus {
            border-bottom-color: transparent;
            border-right-color: #ddd;
            border-left-color: #ddd;
          }
        }
      }

      .sideways.tabs-left {
        left: -50px;
        > li {
          -webkit-transform: rotate(-90deg);
          -moz-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
          -o-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }
      }
      .sideways.tabs-right {
        right: -50px;
        > li {
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }
