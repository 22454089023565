/* debug=on
* Card styling - Related to panels
**/
.card-shadow{
  //-webkit-box-shadow: $card-shadow-dimensions $card-shadow-color;
  //-moz-box-shadow: $card-shadow-dimensions $card-shadow-color;
  //box-shadow: $card-shadow-dimensions $card-shadow-color;
}

//General tools
////Panel formatting for News Release Teaser Panel/Card
.region-content{
  >div{
    >.card-news-release{
      margin-top: 0px;
    }
  }
}

//Table alteration on mobile devices
  @media #{$mobile} {
  .card-news-release .table-responsive {
    width: 100%;
    margin-bottom: 16.5px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: none;
  }
}

//Overall formatting for Cards
.card{
    //General Card formatting
    background-color: $card-bg;
    border: solid 1px $card-inner-border-light;
    margin-top: 15px;
    margin-bottom: 25px;
    padding: 0px;
    -moz-border-radius: $border-radius-base;
    -webkit-border-radius: $border-radius-base;
    border-radius: $border-radius-base;  
    &:first-of-type:not(.card-researcher):not(.card-author){
      //Add increased margin to first card at top of lists.
      //margin-top: 25px;
    }
    &.file{
      //Add increased margin to last card at top of lists.
      margin-bottom: 25px;
    }

    .homepage-feature & {
    }
    h2:not(.panel-title){
      margin-top: 0px;
      padding-bottom: 6px;
      margin-bottom: 10px;
      border-bottom: solid 1px $gray-lighter;
      a{
        font-size: $font-size-x-large;            
      }
    }
  &.primary-economic-indicators{
    background-color: transparent;
    border: none;
    margin: auto;
    padding: 0px;
    h2{
      margin-top: 20px;
      padding-bottom: 6px;
      margin-bottom: 10px;
      border-bottom: none;
      a{
        font-size: $font-size-x-large;            
      }
    }
    table{
      &.bea-indicators {
        > tbody > tr {
          &:first-of-type > td {
            border-top: none;
          }
          > td {
            &:nth-of-type(1) {
              color: rgb(35, 117, 174);
              text-align: left;
            }
            &:nth-of-type(2) {
              text-align: center;
            }
            &:nth-of-type(3) {
            text-align: right;
            }
          }
        }
        &.table-hover tr td {
          cursor: pointer;
        }
        &.number-type {
          margin-left: 4px;
          font-variant-caps: all-small-caps;
          font-size: 100%;
        }
      }
    }
  }
  &.card-news-release {
    border: none;
    table {
      border: none;
    }
    .table {
      border: none;
      > {
        thead > tr > {
          th, td {
            border-bottom: solid 1px rgb(251, 251, 251);
            background-color: rgba(45, 51, 56, 0.25);
          }
        }
        tbody {
          > tr > {
            th, td {
              background-color: rgba(45, 51, 56, 0.1);
            }
          }
          + tbody {
            border: none;
          }
        }  
        thead > tr > {
          th, td {
            padding: 8px;
          }
        }
        tbody > tr > {
          th, td {
            padding: 8px;
          }
        }
      }
      thead tr {
        th:first-of-type, td:first-of-type {
          text-align: left;
          -moz-border-radius: 6px 0px 0px 0px;
          -webkit-border-radius: 6px 0px 0px 0px;
          border-radius: 6px 0px 0px 0px;
        }
        th:last-of-type, td:last-of-type {
          text-align: right;
          -moz-border-radius: 0px 6px 0px 0px;
          -webkit-border-radius: 0px 6px 0px 0px;
          border-radius: 0px 6px 0px 0px;
        }
      }
      tbody tr {
        th:first-of-type, td:first-of-type {
          text-align: left;
          -moz-border-radius: 0px 0px 0px 6px;
          -webkit-border-radius: 0px 0px 0px 6px;
          border-radius: 0px 0px 0px 6px;
        }
        th:last-of-type, td:last-of-type {
          text-align: right;
          -moz-border-radius: 0px 0px 6px 0px;
          -webkit-border-radius: 0px 0px 6px 0px;
          border-radius: 0px 0px 6px 0px;
        }
      }
    }
    > .panel-heading {
      background-color: $card-heading-bg;
      border: solid 1px $card-inner-border;
      [class*="block-views-blockglance-blocks-views-block-"] &,.page-node-type-product &{
        background-color: darken($card-heading-bg, 12%);
        border: solid 1px darken($card-inner-border, 12%);
      }

      border-radius: $border-radius-large $border-radius-large 0px 0px;
      .homepage-feature &{
        border-radius: $border-radius-large;
      }

      .panel-title {
        color: $white;
        font-weight: $font-weight-normal;;
        font-size: 130%;
      }
    }
    .panel-body {
      border: solid 3px $card-inner-border;
      [class*="block-views-blockglance-blocks-views-block-"] &,.page-node-type-product &{
        border: solid 3px darken($card-inner-border, 12%);
      }
      border-radius: 0px 0px $border-radius-large $border-radius-large;
      .homepage-feature & {
        -moz-border-radius: $border-radius-large;
        -webkit-border-radius: $border-radius-large;
        border-radius: $border-radius-large;  
      }
      
      
      .chart h4 {
        display: none;
        //color: rgba(102, 102, 102, 1);
        //font-size: 100%;
        //margin: 5px 5px 15px 5px;
      }
      h5{
        display: none;
      }
      a{
        &.chart-embedded{
          img {
            padding: 5px;
            background-color: $white;
            border: solid 1px rgba(45, 51, 56, 0.2);
            -moz-border-radius: 4px 4px 4px 4px;
            -webkit-border-radius: 4px 4px 4px 4px;
            border-radius: 4px 4px 4px 4px;
            &:hover {
              border: solid 1px $card-img-hover-color;
              -webkit-box-shadow: $card-img-hover-dimnesions $card-img-hover-color;
              -moz-box-shadow: $card-img-hover-dimnesions $card-img-hover-color;
              box-shadow: $card-img-hover-dimnesions $card-img-hover-color;
              cursor: pointer;
            }
          }
        }
      }
      .release-dates{
        .field--name-field-description{
          .note{
            font-size: $font-size-med-sm;
            color: $gray;
          }
          ul{
            &:not(.contextual-links){
              display: table;
              width: 100%;
              margin: 12px 0px 0px 0px;
              padding: 0px;
              @media #{$mobile}{
              text-align: center;
              }
              li{
                display: table-cell;
                width: 50%;
                background-image: none !important;
                margin: 0px;
                padding: 0px;
                font-size: $font-size-med-sm;
                &:last-of-type{
                @media #{$tablet}{
                  text-align: right;
                }
                  padding-left: 0px !important;
                  padding-right: 16px;
                }
                strong{
                @media #{$mobile}{
                  display: block;
                }                  
                  color: darken($gray-light, 2%);
                  font-weight: normal;
                }
              }
              &:last-of-type{
                border-top: none;  
              }
              &:first-of-type{
                border-top: solid 1px rgba(0, 0, 0, 0.1);
              }
            }
            &:first-of-type{
              li{
                padding-top: 16px;
              }
            }
          }
        }
      }
    } 
  }
  > .panel-footer{
    background-color: $card-footer-bg;
    border: none;
    border-radius: 0px;
  }
  .btn{
    &.btn-default{
    margin-bottom: 10px;
    }
  }

  &.card-document{
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 15px 25px;
    &:first-of-type{
      margin-top: 25px;
    }
    h2{
      margin-top: 0px;
      padding-bottom: 6px;
      margin-bottom: 10px;
      //float: left;
      border-bottom: solid 1px $gray-lighter;
      a{
        font-size: $font-size-x-large;            
      }
    }
    .paper-author{
      text-align: right;
    }
    .presentation-note{
      font-style: italic;
    }
    .paper-publication-date, .paper-mod-date{
      text-align: right;
      span{
        padding-right: 4px;
        color: darken($gray-light, 2%);
        font-size: $font-size-med-sm;
      }
    }
  }
  &.card-glossary{
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 15px 25px;
    &:first-of-type{
      margin-top: 0px;
    }
    .glossary-synopsis{
      &.col-sm-8{
        padding-left: 0px;
      }
    }
    .glossary-related-terms{
      .label-related-terms{
        display: block;
        margin-top: 0px;
        color: darken($gray-light,2%);
        font-weight: $font-weight-heavy;
      }
      ul{
        margin-top: 5px;
        margin-left: 5px;
        margin-bottom: 5px;
        padding-left: 0px;
      }
    }
  }
  &.card-icon {
    display: block;
    padding: 20px;
    &:not(.card-no-border){
      
      @media #{$mobile}{
        background-color: $card-bg-mobile;
        border: solid 2px $card-inner-border-medium;
        border-radius: $border-radius-massive;
      }         
      @media #{$tablet}{
        background-color: $card-bg;
        border: solid 3px $card-inner-border;
        border-radius: $card-border-radius;
        -webkit-box-shadow: $card-shadow-dimensions $card-shadow-color;
        -moz-box-shadow: $card-shadow-dimensions $card-shadow-color;
        box-shadow: $card-shadow-dimensions $card-shadow-color;
      } 
    }
    &.card-no-border{
      border: solid 3px transparent;
      background-color: $white;
      border-radius: $card-border-radius;      
      &:first-of-type{
        //margin-top: 0px;
      }
    }
    .media{
      .media-object {
        border: solid 1px rgba(0, 0, 0, 0);
        width: 75px;
        height: auto;
      }
      &.media-lg{
        .media-object {
          width: 125px;
          height: auto;
        }
      }
      &:hover .media-object {
        border: solid 1px rgba(0, 0, 0, 0.5);
      }
      .media-left{
        .text-primary{
          color: $brand-orange;
        }
      }
      img{
        border-radius: $border-radius-base;
      }
      .media-body {
        @media #{$mobile}{
          padding-left: 0px;
        }         
        @media #{$tablet}{
          padding-left: 15px;
        }         
        color: $gray;
        h3 {
          border-bottom: solid 1px rgba(45, 51, 56, 0.15);
          padding-bottom: 8px;
          margin-bottom: 8px;
          span {
            display: block;
            font-weight: normal;
            font-size: 60%;
            color: rgba(0, 0, 0, 0.5);
          }
        }
        .fa{
          float:right;
          margin-top: 3px;
        }
        .list-inline {
          margin-left: 0px;
          display: inline-block;
          padding-left: 5px;
          padding-right: 5px;
          @media #{$mobile}{
            > li {
              display: block;
              background-image: url(/sites/default/files/bullet-orange.png);
              padding-left: 5px;
            }          
          }         
        }        
//        .table{
//          margin: 0px;
//          font-size: $font-size-base;
//          tbody{
//            tr{
//              th, td{
//                border-color: transparent;
//                text-align: left;                
//              }
//              th{
//                font-weight: $font-weight-heavy;
//                white-space: nowrap;
//              }
//              td{
//              }
//            }
//          }
//        }
      }
    }
    &:hover {
    border: solid 3px $brand-orange;
    background-color: $brand-orange;
    text-decoration: none;
    .media-left{
      .text-primary{
        color: $white;
      }
    }
      
    .media-body{
      color: rgba(255, 255, 255, 0.75);
      h3, h4{
        color: $white;
        border-bottom: solid 1px rgba(255, 255, 255, 0.15);
        span {
          color: $white;
        }
      }
      ul{ 
        &.list-inline{
          li{
            background-image:url(/sites/default/files/bullet-white.png) !important;
            &:first-of-type{
              background-image: none !important;
            }
          }
        }
      }
      .table{
        color: $white;
      }
    }
    }
  }
  &.card-table{
    display: block;
    padding: 15px 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    &:first-of-type{
      padding: 20px 20px 20px 20px;
      //margin-top: 0px;
      margin-bottom: 15px;
    }
    &:not(.card-no-border){
      background-color: $card-bg;
      border: solid 3px $card-inner-border;
      border-radius: $border-radius-large;
      -webkit-box-shadow: $card-shadow-dimensions $card-shadow-color;
      -moz-box-shadow: $card-shadow-dimensions $card-shadow-color;
      box-shadow: $card-shadow-dimensions $card-shadow-color;
    }
    &.card-no-border{
      border: solid 3px transparent;
      background-color: $white;
    }
    .media{
      .media-object {
        border: solid 1px rgba(0, 0, 0, 0);
        width: 75px;
        height: auto;
      }
      &.media-lg{
        .media-object {
          width: 125px;
          height: auto;
        }
      }
      .media-body {
        padding-left: 0px;
        color: #666666;
        h3 {
          border-bottom: solid 1px rgba(45, 51, 56, 0.15);
          padding-bottom: 8px;
          margin-bottom: 0px;
          a{
            color: $headings-color;
            &:hover{
              color: $brand-orange;
            }
          }
          span {
            display: block;
            font-weight: normal;
            font-size: 60%;
            color: rgba(0, 0, 0, 0.5);
          }
          .fa-arrow-circle-right{
            color: $brand-orange;
          }
        }
        .fa{
          //float:right;
          //margin-top: 3px;
        }
        .table{
          margin: 0px;
          font-size: $font-size-base;
          thead{
            tr{
              &.thead-mini-row{
                th{
                  display: none;
                  border:none;
                  padding: 2px;
                  font-size: $font-size-x-small;
                  &.bg-default{
                    background-color: rgba(45, 51, 56, 0.15);
                  }
                }
              }
            }
          }
          tbody{
            tr{
                &.no-divider{
                  th, td{border: none;}
                }
              th, td{
                padding: 8px;
                vertical-align: middle;
              }
              th{
                font-weight: $font-weight-heavy;
                white-space: nowrap;
                text-align: left;
                width: 75px;
                vertical-align:text-top;
              }
              td{
                &:nth-of-type(1){
                  text-align: left;
                }
                &:nth-of-type(2){
                  //border-left: solid 1px rgba(45, 51, 56, 0.15);
                  text-align: right;
                  white-space: nowrap;
                  width: 130px;
                }
                &:nth-of-type(3){
                  text-align: right;
                  white-space: nowrap;
                  width: 180px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.card-author{
    background-color: $white;
    border: none;
    margin-top: 0px;
    margin-bottom: 20px;
    padding: 10px;
    //border: solid 1px $card-inner-border-light;
    .author-picture{
      height: 100px;
      width: 100px;
      float: left;
      border: none;
      margin-right: 15px;
      //background-color: $brand-slate;
    }
    .author-title{
      font-size: $font-size-large;
    }
    .author-position-title{
      display: block;
      //border-bottom: solid 1px $card-inner-border-light;
      font-size: $font-size-med-sm;
    }
  }
  &.card-researcher{
    background-color:transparent;
    border: none;
    &.teaser{
      //article{
      position: relative;
      border-bottom: solid 1px $card-inner-border-light;
      margin: 25px;
      padding: 0px 5px;

//      @medai#{$wide}{
//      margin: 25px;
//      }
//      @media #{$normal} {
//      margin: 25px;
//      } 
//      @media #{$tablet}{
//      margin: 25px;
//      }        
      @media #{$sub-tablet}{
      margin: 25px 0px;
      } 
      .card-url{
        font-size: $font-size-h2;
        //color: $headings-color;
        font-weight: $font-weight-heavy;
        @media #{$sub-tablet}{
        font-size: $font-size-h3;
        } 
      }
      .bottom-align{
        position: absolute;
        bottom: 0px;
        display: block;
        @media #{$sub-tablet}{
        font-size: 80%;
        margin-right: 30%;
        } 
      }
      .links.inline.list-inline{
        display: none;
      }
      .field--name-field-picture{
        float:right;
        position: relative;
        width: 30%;
        height: auto;
      }
      .field--name-field-author-title{
        position: relative;
        color: $gray;
      }
      .field--name-field-directorate, .field--name-field-author-division{
        color: $gray;
        position: relative;
      }
    }
  }
}

.card-document-teaser {
  background-color: $card-bg;
  border: solid 3px $card-inner-border;
  border-radius: 0px;
  -webkit-box-shadow: $card-shadow-dimensions $card-shadow-color;
  -moz-box-shadow: $card-shadow-dimensions $card-shadow-color;
  box-shadow: $card-shadow-dimensions $card-shadow-color;
  .card-details {
    p, h2 {
      margin: 0px;
      padding: 0px;
    }
    .card-type {
      margin-bottom: 8px;
    }
    .card-title {
        font-size: 130%;
        border-bottom: solid 1px rgba(0, 0, 0, 0.1);
        padding-bottom: 6px;
        margin-bottom: 4px;
      span:not(.file-type) {
        float: right;
        font-weight: normal;
        font-size: 70%;
        color: rgba(0, 0, 0, 0.25);
        position: relative;
        top: 8px;
        margin-left:10px;
      }
    }
    .card-abstract {
      margin-top: 8px;
      margin-bottom: 8px;
    }
    .card-author, .card-published {}
    .card-author {
      text-align: right;
    }
    .card-published {
      text-align: right;
      .field--name-field-publication-date{
        float:right;
      }
      .modified {
        border-right: solid 1px rgba(0, 0, 0, 0.5);
        padding-right: 8px;
        margin-right: 8px;
      }
      .periodical {
        font-style: italic;
        margin-left: 8px;
      }
      .modified span, .published span {
        color: rgba(0, 0, 0, 0.5);
        margin-right: 8px;
        font-size: 80%;
      }
    }
  }
  .card-supplements {
    margin-left: 0px;
    margin-bottom: 20px;
    .title {
      color: rgba(0, 0, 0, 0.5);
      list-style-image: none;
      list-style-type: none;
      margin-left: -15px;
      padding-bottom: 4px;
    }
  }
  &.collapsed {
    background-color: transparent;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

.card-news-release{
  .card-no-border &{
    border-style: none;
    background-color: transparent;
    
    > .panel-heading {
      background-color: $gray-lighter;
      border: none;
      padding-top: 9px;      
      padding-bottom: 12px;      
      .panel-title {
        color: $brand-slate;
        font-weight: $font-weight-heavy;
      }
    }
    .panel-body {
      border: none;
    }
    > .panel-footer{
      background-color: transparent;
    }
  }
  &.panel-shadow {
    //-webkit-box-shadow: none;
    //-moz-box-shadow: none;
    //box-shadow: none;
  }

}
  .card-no-border{
    .view-content{
      h3{
        padding: 10px 15px;
        margin: 0px;
        background-color: $card-heading-bg;
        border: none;
        border-radius: 0px;
        color: $white;
        font-weight: $font-weight-normal;;
        font-size: 130%;
        &+ .views-row{
          .card{
            padding-top: 10px;          
          }
          //margin-top: 10px;
        }
      }
      .views-row{
        border-color: $card-heading-bg;
        border-style: none solid none solid;
        .card{
          padding: 0px 10px 10px 10px;
          margin: 0px;
        }        
        &:first-of-type{
          border-style: solid solid none solid;
        }
        &:last-of-type{
          border-style: none solid solid solid;
        }
      }
    }
  }


.panel-no-heading.card.card-news-release .panel-body{
    border-radius: $border-radius-large;    
  }




