/* debug=on
* Footer styling.
*/

//* Page Last Modified Formatting for lack of better place
.full-width{
  margin-top: 15px;
  background-color: $gray-lighter;
  .last-modified{
    padding: 8px 0px;
    font-size: $font-size-med-sm;
    color: darken($gray-light, 10%);
    .download-link{
      text-align: right;
      a{
        color: darken($link-color, 5%);
      }      
    }
    .date-info{
      text-align: left;
    }
    @media #{$mobile} {
      .download-link{
      text-align: center;
      }
      .date-info{
      text-align: center;
      }
    }
  }
}
////Region Footer
//Footer
footer{
  &.container {
    padding: 25px 0;
    background-color: $brand-primary;
    margin-top: 0px;
    width: 100%;
  }
  ul li a{
        color: $white-opacity-75 !important;
        &:hover{
        color: $white !important;
        text-decoration: underline !important;
        }
      }

  h4 {
    border-bottom: 1px solid $white;
    color: $white;
    font-size: $font-size-footer-h4;
    font-weight: 600;
    padding-bottom: 4px;
    margin-bottom: 0.75em;
    span {
      font-size: $font-size-footer-span;
      margin-left: 10px;
    }    
    @media #{$mobile}{
      span {
        display: block;
        margin: 5px 0px 5px 0px;
      }
    }     
  }

  ul {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    li {
      font-size: $font-size-footer;
      list-style: outside none none;
      margin-bottom: 5px;
      &.text-large{
        font-size: $font-size-x-large;
      }
    }
  }
  .row{
    &.row-with-buttons{
      padding-bottom: $row-margin-bottom;
    }
  }
  .btn{
    font-size: $font-size-footer;
    margin-bottom: 10px;
    &.btn-default{
      color: $list-group-link-color;
    }
    &.btn-invisible{
      color: $white-opacity-75;
      ;text-align: left;
      &:hover{
        color: $white;
        border-color: $white;
      }
    }
  }
}
