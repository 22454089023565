/* debug=off 
 * $file
 * Visual styles for fields.
 */
.field--label {
  font-weight: 500;
}
.field--label-inline .field--label,
.field--label-inline .field--items {
  float: left; /*LTR*/
}
.field--label-inline .field--label,
.field--label-inline > .field--item,
.field--label-inline .field--items {
  padding-right: 0.5em;
}
[dir="rtl"] .field--label-inline .field--label,
[dir="rtl"] .field--label-inline .field--items {
  padding-left: 0.5em;
  padding-right: 0;
}
.field--label-inline .field--label::after {
  content: ':';
}
