/**
 * Chosen JS Select Box styles.
**/
 
select#edit-field-author-reference-target-id {
} 

.form-control.chosen-container {
    background: transparent !important;
    border: 0px !important;	
	border-radius: none;
	box-shadow: none;
	padding: 4px 0px 6px 0px !important;
} 

.chosen-container-single .chosen-single {
	box-shadow: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	background: #fff;
	padding: 4px 0 0 8px;
	height: 36px;
}

.chosen-container-single .chosen-single div b {
	background: url("../images/chosen-sprite.png") no-repeat 4px 7px;
}
 
.chosen-drop { 

	ul.chosen-results { 

		li {
		background-image: none !important;
		padding: 0 0 4px 2px !important;
		}
	}
}