/* debug=off 
 * Icon styles.
 */
a {
  &.icon-before .glyphicon {
    margin-right: .25em;
  }
  &.icon-after .glyphicon {
    margin-left: .25em;
  }
}
.fa{
  margin-right: .25em;
  margin-right: .25em;
}

.btn {
  &.icon-before .glyphicon {
    margin-left: -.25em;
    margin-right: .25em;
  }
  &.icon-after .glyphicon {
    margin-left: .25em;
    margin-right: -.25em;
  }
}

//Formatting for triangles used on homepage PEI
[class*="glyphicon-triangle-"] {
        margin-left: 1em;
}
.glyphicon-triangle-top {
        color: rgb(65, 150, 65);
}
.glyphicon-triangle-bottom {
        color: rgb(193, 46, 42);
}

