/* debug=off 
 * Panel styling..
 */

//General tools
.panel-shadow {
    -webkit-box-shadow: 3px 3px 12px 1px rgba(0,0,0,0.13);
    -moz-box-shadow: 3px 3px 12px 1px rgba(0,0,0,0.13);
    box-shadow: 3px 3px 12px 1px rgba(0,0,0,0.13);
}

.panel-title {
  display: block;
  font-size: $font-size-base;
  //margin: -10px -15px;
  //padding: 10px 15px;
  &, &:hover, &:focus, &:hover:focus {
    color: inherit;
  
}
  &:focus, &:hover {
    text-decoration: none;
  }
}
.panel-body:before, .panel-body:after {
  content: " ";
  display: table;
}

.panel-solid{
    background-color: $panel-default-border;
}

.region-content{
    >div{
        >.card-news-release{
            margin-top: 0px;
        }
    }
}

.panel{
  &.system-menu-block{
    &.panel-inverse{
      background-color: $panel-inverse-heading-bg;
      border:solid 1px $panel-inverse-border;
      .panel-heading{
      .panel-title{
        color: $panel-inverse-text;
      }
      }
      .list-group-item{
        &.active{
        background-color: $white;
        color: $btn-default-color;
        background-image: url(/sites/default/files/corner-tag-inverse.png);
        background-repeat: no-repeat;
        background-position: -66px center;
        }
      }
    } 
  }
} 




//Panel within Accordions
#accordion, [id*="vertical_container_"]{
  .panel{
    .panel-heading{
      padding: 0px;
      h3{
        margin: 0px;
        font-size: $font-size-base;
      }
      a{
        color: $headings-color;
        display: block;
        padding: 10px 15px;
      }
      &.active{
        background-color: $accordion-hover-bg;
        a{
          color: $accordion-hover-text;		
        }        
      }
      &:hover{
        padding: 0px;
        background-color: $accordion-hover-bg;
        a{
          color: $accordion-hover-text;		
        }
      }		
    }
    .panel-body{
      border: solid 1px $accordion-hover-bg;
      .page-node-type-product &, .apps-bea-gov &{
        padding: 0px;
      }
      h4{
        margin: 10px;
        padding: 0px;
        font-size: $font-size-base;
      }
    }
  }
}

//Form Panel formatting - content block
.view-release-archive{
  .panel-form{
    color: $panel-form-text;
    border-color: $panel-form-border;
    background-color: $panel-form-heading-bg;
  }
}




//Panel formatting for News Release Teaser Panel/Card
//.homepage-feature {
//margin-top: 15px;
//margin-bottom: 25px;
//&.panel-default {
//background-color: $card-bg;
//border: none;
//border-radius: 0px;
//-webkit-box-shadow: $card-shadow-dimensions $card-shadow-color;
//-moz-box-shadow: $card-shadow-dimensions $card-shadow-color;
//box-shadow: $card-shadow-dimensions $card-shadow-color;
//> .panel-heading {
//background-color: $card-heading-bg;
//border: none;
//border-radius: 0px;
//.panel-title {
//color: $white;
//font-weight: $font-weight-normal;;
//font-size: 130%;
//}
//}
//}
//table {
//border: none;
//}
//.table {
//border: none;
//> {
//thead > tr > {
//th, td {
//border-bottom: solid 1px rgb(251, 251, 251);
//background-color: rgba(45, 51, 56, 0.25);
//}
//}
//tbody {
//> tr > {
//th, td {
//background-color: rgba(45, 51, 56, 0.1);
//}
//}
//+ tbody {
//border: none;
//}
//}
//thead > tr > {
//th, td {
//padding: 8px;
//}
//}
//tbody > tr > {
//th, td {
//padding: 8px;
//}
//}
//}
//thead tr {
//th:first-of-type, td:first-of-type {
//text-align: left;
//-moz-border-radius: 6px 0px 0px 0px;
//-webkit-border-radius: 6px 0px 0px 0px;
//border-radius: 6px 0px 0px 0px;
//}
//th:last-of-type, td:last-of-type {
//text-align: right;
//-moz-border-radius: 0px 6px 0px 0px;
//-webkit-border-radius: 0px 6px 0px 0px;
//border-radius: 0px 6px 0px 0px;
//}
//}
//tbody tr {
//th:first-of-type, td:first-of-type {
//text-align: left;
//-moz-border-radius: 0px 0px 0px 6px;
//-webkit-border-radius: 0px 0px 0px 6px;
//border-radius: 0px 0px 0px 6px;
//}
//th:last-of-type, td:last-of-type {
//text-align: right;
//-moz-border-radius: 0px 0px 6px 0px;
//-webkit-border-radius: 0px 0px 6px 0px;
//border-radius: 0px 0px 6px 0px;
//}
//}
//}
//.panel-body {
//.chart h4 {
//color: rgba(102, 102, 102, 1);
//font-size: 100%;
//margin: 5px 5px 15px 5px;
//}
//border: solid 3px $card-inner-border;
//border-radius: 0px;
//img {
//padding: 5px;
//background-color: $white;
//border: solid 1px rgba(45, 51, 56, 0.2);
//-moz-border-radius: 4px 4px 4px 4px;
//-webkit-border-radius: 4px 4px 4px 4px;
//border-radius: 4px 4px 4px 4px;
//&:hover {
//border: solid 1px $card-img-hover-color;
//-webkit-box-shadow: $card-img-hover-dimnesions $card-img-hover-color;
//-moz-box-shadow: $card-img-hover-dimnesions $card-img-hover-color;
//box-shadow: $card-img-hover-dimnesions $card-img-hover-color;
//cursor: pointer;
//}
//}
//ul{
//margin: 15px 0px 0px 0px;
//padding: 0px;
//li{
//padding-left: 16px;
//list-style-type: none;
//background-image:url(/sites/default/files/bullet-orange.png);
//background-repeat: no-repeat;
//background-position: left center;
//}
//}
////.col-md-6:last-of-type {
////border-left: solid 1px rgba(45, 51, 56, 0.09);
////}
//}
//.panel-footer{
//background-color:$card-inner-border;
//}
//}
