/* debug=on  */
////Tables
//-------------------------------------------------

//table {
//  background-color: $table-bg;
//  //Table cell sizing - Reset default table behavior
//  col {
//    &[class*="col-"] {
//      position: static; //Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
//      float: none;
//      display: table-column;
//    }
//  }
//  td,
//  th {
//    &[class*="col-"] {
//      position: static; //Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
//      float: none;
//      display: table-cell;
//    }
//  }
//}
//
//caption {
//  padding-top: $table-cell-padding;
//  padding-bottom: $table-cell-padding;
//  color: $text-muted;
//  text-align: left;
//}
//
//th {
//  text-align: left;
//}
//
////Baseline styles
//
//.table {
//  width: 100%;
//  max-width: 100%;
//  margin-bottom: $line-height-computed;
//  //Cells
//  > thead,
//  > tbody,
//  > tfoot {
//    > tr {
//      > th,
//      > td {
//        padding: $table-cell-padding;
//        line-height: $line-height-base;
//        vertical-align: top;
//        border-top: 1px solid $table-border-color;
//      }
//    }
//  }
//  //Bottom align for column headings
//  > thead > tr > th {
//    vertical-align: bottom;
//    border-bottom: 2px solid $table-border-color;
//  }
//  //Remove top border from thead by default
//  > caption + thead,
//  > colgroup + thead,
//  > thead:first-child {
//    > tr:first-child {
//      > th,
//      > td {
//        border-top: 0;
//      }
//    }
//  }
//  //Account for multiple tbody instances
//  > tbody + tbody {
//    border-top: 2px solid $table-border-color;
//  }
//
//  //Nesting
//  .table {
//    background-color: $body-bg;
//  }
//}
//
////Condensed table w/ half padding
//
//.table-condensed {
//  > thead,
//  > tbody,
//  > tfoot {
//    > tr {
//      > th,
//      > td {
//        padding: $table-condensed-cell-padding;
//      }
//    }
//  }
//}

//Bordered version
////Add borders all around the table and between all the columns.

//.table-bordered {
//border: 1px solid $table-border-color;
//> thead,
//> tbody,
//> tfoot {
//> tr {
//> th,
//> td {
//border: 1px solid $table-border-color;
//}
//}
//}
//> thead > tr {
//> th,
//> td {
//border-bottom-width: 2px;
//}
//}
//}


//Zebra-striping
////Default zebra-stripe styles (alternating gray and transparent backgrounds)

//.table-striped {
//  > tbody > tr:nth-of-type(odd) {
//    background-color: $table-bg-accent;
//  }
//}
//
////Hover effect
//////Placed here since it has to come after the potential zebra striping
//
//.table-hover {
//  > tbody > tr:hover {
//    background-color: $table-bg-hover;
//  }
//}
//
//Table backgrounds
////Exact selectors below required to override `.table-striped` and prevent
//inheritance to nested tables.

//Generate the contextual variants
//@include table-row-variant('active', $table-bg-active);
//@include table-row-variant('success', $state-success-bg);
//@include table-row-variant('info', $state-info-bg);
//@include table-row-variant('warning', $state-warning-bg);
//@include table-row-variant('danger', $state-danger-bg);

//Responsive tables
////Wrap your tables in `.table-responsive` and we'll make them mobile friendly
//by enabling horizontal scrolling. Only applies <768px. Everything above that
//will display normally.

//.table-responsive {
//  overflow-x: auto;
//  min-height: 0.01%; //Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)
//
//  @media screen and (max-width: $screen-xs-max) {
//    width: 100%;
//    margin-bottom: ($line-height-computed * 0.75);
//    overflow-y: hidden;
//    -ms-overflow-style: -ms-autohiding-scrollbar;
//    border: 1px solid $table-border-color;
//
//    //Tighten up spacing
//    > .table {
//      margin-bottom: 0;
//
//      //Ensure the content doesn't wrap
//      > thead,
//      > tbody,
//      > tfoot {
//        > tr {
//          > th,
//          > td {
//            white-space: nowrap;
//          }
//        }
//      }
//    }
//
//    //Special overrides for the bordered tables
//    > .table-bordered {
//      border: 0;
//
//      //Nuke the appropriate borders so that the parent can handle them
//      > thead,
//      > tbody,
//      > tfoot {
//        > tr {
//          > th:first-child,
//          > td:first-child {
//            border-left: 0;
//          }
//          > th:last-child,
//          > td:last-child {
//            border-right: 0;
//          }
//        }
//      }
//
//      //Only nuke the last row's bottom-border in `tbody` and `tfoot` since
//      //chances are there will be only one `tr` in a `thead` and that would
//      //remove the border altogether.
//      > tbody,
//      > tfoot {
//        > tr:last-child {
//          > th,
//          > td {
//            border-bottom: 0;
//          }
//        }
//      }
//
//    }
//  }
//}

//=======================================================================================================
//BEA Custom..
//=======================================================================================================


//News Release Table formatting  
body:not(.apps-bea-gov){ //Ignore itables tables and apply to rest of site.

//  //General Table Styles
  table:not(.bea-indicators){ //:not(.table-reset)
    color: $headings-color;
    margin: 20px auto 50px auto;
  font-size: 95%;
    width: 95%;
    &.table-sm{
      font-size: 85%;
      width: 55%;
    }
    &.table-md {
      font-size: 90%;
      width: 75%;
    }
    //&.table-lg{
    // Default for unlabeled tables.
    //}
    .BoldStubStyle, .nsb{
      td, th{
      //font-weight: $font-weight-heavy;
      }
    }
    td, th{
      &.BoldStubStyle, &.nsb{
      //font-weight: $font-weight-heavy;
      }
    }
    caption {
      text-align: center;
      font-size: 120%;
      font-weight: $font-weight-normal;
      color: $gray;
      //padding-bottom: 25px;
      span{
        display: block;
        font-size: $font-size-med-sm;
      }
    }
    thead{
      tr{
        &.text-muted{
          th{
            //font-size: $font-size-small;
            padding: 3px;
          }
        }
        &:last-of-type{
          th{
            border-bottom: solid 3px $table-border-color;
          }
        }
      }
    }
    thead,
    tbody,
    tfoot{
      tr{
        th{
          //font-weight: $font-weight-normal;
          border-bottom-width: 1px;
          //text-align: center;
        }
      }
    }
    tbody,
    tfoot{
      tr{
        > th{
          //font-weight: $font-weight-normal;
          //text-align: right;
          &:first-of-type{
            //text-align: left;
          }
          + td{
            //text-align: right !important;
          }
        }
        td{
          //text-align: right;
          //font-weight: $font-weight-light;
          &:first-of-type{
            //text-align: left;
          }
          &.p0{}
          &.p2{
            padding-left: $table-stub-indent;
          }
          &.p4{
            padding-left: $table-stub-indent + $table-stub-indent;
          }
          &.p6{
            padding-left: $table-stub-indent + $table-stub-indent + $table-stub-indent;
          }
        }
      }
    }
    tfoot{
      //font-size: $font-size-small;
      tr{
        th{
          &[colspan]:not([colspan="1"]) {
            //text-align: left;
            background-color: $table-border-color;
          }
        }
        &:first-of-type{
          th,
          td{
            //text-align: left;
            border-top: solid 3px $table-border-color;
          }
        }
      }
    }
  }

  
  [class*="table-release-pi"]{
    border: solid 3px $brand-slate;
    border-collapse: separate;
    border-radius: 0px 0px 6px 6px;
    -webkit-border-radius: 0px 0px 6px 6px; 
    -moz-border-radius: 0px 0px 6px 6px;
    caption {
      color: $white-opacity-90 !important;
      background-color: $brand-slate;
      border-radius: 6px 6px 0px 0px;
      -webkit-border-radius: 6px 6px 0px 0px; 
      -moz-border-radius: 6px 6px 0px 0px;
    }
    thead{
      color: $gray-base;
      tr{
      }
    }
    thead,
    tfoot{
      tr{
        th,td{
          color: $white-opacity-90;
          background-color: lighten($brand-slate, 20%);
          border-bottom-width: 0px !important;
          border-left-width: 0px !important;
          text-align: center;
          font-weight: normal;
        }
      }
    }
    tbody,
    tfoot{
      tr{
        > th{
          //font-weight: $font-weight-normal;
          //text-align: right;
          &:first-of-type{
            //text-align: left;
          }
          + td{
            //text-align: right !important;
          }
        }
        td{
          //text-align: right;
          //font-weight: $font-weight-light;
          &:first-of-type{
            //text-align: left;
          }
        }
      }
    }
    tfoot{
      //font-size: $font-size-small;

    }
  } 
  
  .table-release-pi-01{
    thead{
      tr{
        &:nth-of-type(3){
          th{
            font-size: 80%;
            color: $white-opacity-90;
          }
        }
      }
    }
    tbody{
      tr{
        &:nth-of-type(2),&:nth-of-type(4),&:nth-of-type(5),&:nth-of-type(7),&:nth-of-type(8),&:nth-of-type(10),&:nth-of-type(11),&:nth-of-type(13),&:nth-of-type(14){
          th{
            padding-left: 20px;
            font-weight: $font-weight-normal;
          }
        }
        td{
          text-align:center;
        }
      }
      tr{
        &:nth-of-type(12){
          td{
            font-size: 80%;
          }
        }
      }}
    tfoot{
      tr{
        th,td{
          border-top-width: 0px !important;
        }
      }
    }

  }
  
  
  .table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
    vertical-align: middle;
}
  
//Custom Style modifications for homepage Primary Economic Indicators
  table.bea-indicators {
  > tbody > tr {
    &:first-of-type > td {
      border-top: none;
    }
    > td {
      &:nth-of-type(1) {
        color: rgb(35, 117, 174);
        text-align: left;
      }
      &:nth-of-type(2) {
        text-align: center;
      }
      &:nth-of-type(3) {
        text-align: right;
      }
    }
  }
  &.table-hover tr td {
    cursor: pointer;
  }
  &.number-type {
    margin-left: 4px;
    font-variant-caps: all-small-caps;
    font-size: 100%;
  }
  [class*="glyphicon-triangle-"] {
    margin-left: 1em;
  }
  .glyphicon-triangle-top {
    color: rgb(65, 150, 65);
  }
  &.glyphicon-triangle-bottom {
    color: rgb(193, 46, 42);
  }
}
//Custom Table for Current Release Card - Data by Topic

  .card-news-release{
    table{
        &.bea-special{
            width:90%;
            //margin: 20px auto 15px auto;
            margin: 0px auto;
                tbody{
                font-size: 110%;
                tr{
                    td{
                        border-bottom: solid 1px $gray-lighter;
                        text-align: center;
                        padding: 5px 3px;
                        &:first-of-type{text-align: left;}
                        &:last-of-type{text-align: right;}
                        
                    }
                }
                tr:first-of-type{
                
                    td{
                        padding-top: 10px;
                        border-top: none;
                    }
                }
                tr:last-of-type{
                    td{
                        padding-bottom: 10px;
                        border-bottom: none;
                    }
                }
            }
        }
        &:not(.bea-special){
            thead{
                display: none;
            }             
        }
    }
}
}

.scheduled-releases{
  caption{
    font-size: $font-size-h2;
    color: $headings-color;
  }
}  

.view-release-archive, .view-release-schedule{
  .table{
    .release-row{
      td:last-of-type{
        white-space: nowrap;
      }
    }
  }
}

//.page-node-type-release {
////  .table-responsive{
////    text-align: center;
////  }
////  .table-bordered{
////    border-width: 0px;
////    border-color: transparent;
////  }
////  
//  .table{
//    margin-left: auto;
//    margin-right: auto;
//    border: solid 1px $gray;
//    border-radius: 0px 0px 3px 3px;
//    border-collapse: separate;
//    .table-cell-indent-01{
//      padding-left: 25px
//    }
//    .table-cell-indent-02{
//      padding-left: 50px
//    }
//    .table-row-highlight{
//      th,td{
//        font-weight: $font-weight-heavy !important;
//      }
//    }
//    &.table-xs{
//      width: 40%;
//      thead,tbody,tfoot{
//        font-size: 75%;
//      }
//    }
//    &.table-sm{
//      width: 60%;
//      thead,tbody,tfoot{
//        font-size: 85%;
//      }
//    }
//    &.table-md{
//      width: 80%;
//      thead,tbody,tfoot{
//        font-size: 95%;
//      }
//    }
//    &.table-lg{
//      width: 100%;
//    }
//    tr{
//      th, td{
//        font-weight: $font-weight-normal;  
//        vertical-align: middle;
//      }
//      th{
//        border-left: solid 1px $gray;
//      }
//      td{
//        &:first-of-type{
//          border-left: solid 1px $gray;
//        }
//
//      }
//      th + td{
//          border-left-width: 0px !important;
//      }
//    }
//    caption{
//      text-align: center;
//      font-weight: $font-weight-heavy;
//      color: $white;
//      font-size: $font-size-x-large;
//      border-radius: 3px 3px 0px 0px;
//      background-color: $gray-darker;
//      span{
//        display: block;
//        font-weight: $font-weight-normal;
//        color: $white-opacity-60;
//        font-size: $font-size-med-sm;
//      }
//    }
//    
//    thead, tbody, tfoot{
//      tr{
//        th,td{
//          text-align: center;
//        }
//      }
//    }
//    
//    thead{
//      background-color: $gray;
//      border-color: $gray;
//      tr{
//        th, td{
//          text-align: center;
//          color: $white;
//          border-color: $white-opacity-20;
//          border-top-width: 0px;
//          border-right-width: 1px;
//          border-bottom-width: 1px;
//          border-left-width: 0px;
//          &[rowspan]{
//            border-right: solid 1px $white-opacity-20;
//            border-left: solid 1px $white-opacity-20;
//          }
//        }
//        &:last-of-type{
//          th,td{
//            border-bottom-color: $brand-slate;
//            border-bottom-width: 1px;
//          }
//        }
//      }
//    }
//    tbody{
//      tr{
//        &.divider{
//          td{
//            font-size: $font-size-med-sm;
//          }
//        }
//        th{
//          text-align: left;
//        }
//        td{
//          &:first-of-type{
//            text-align: left;
//          }
//        &:last-of-type{
//          border-right: solid 1px $gray;
//        }          
//        }
//        th + td{
//          text-align: center !important;
//        }
//        td{
//          &[colspan]{
//            background-color:$gray-lighter;
//            font-size: $font-size-med-sm;
//            padding: 4px;
//          }
//        }
//      }
//      
//    }
//    tfoot{
//      tr{
//        
//        td{
//          text-align: left;
//          background-color: $gray-darker;
//          color: $white-opacity-60;
//          font-size: $font-size-med-sm;
//          p{
//            margin-bottom: 3px;
//          }
//          ul{
//            li{
//              background-image: url(/sites/default/files/bullet-white.png) !important;
//            }
//          }
//          ol{
//            margin-top: 4px;
//            margin-bottom: 6px;
//            padding-left: 20px;
//            li{}
//          }
//        }
//      }
//
//    }
//  .table-bordered {
//box-sizing: border-box;
//thead{
//tr{
//th{
//background-color: $gray-super-light;
//&:first-of-type{
//background-color: $gray-lighter;
//border-top-color: $gray-lighter;
//border-bottom-color: $gray-lighter;
//} 
//}
//&:last-of-type{
//th{
//border-bottom: solid 3px $table-border-color;
//}
//}
//
//}
//}
//tbody{
//th{
//background-color: $gray-super-light;
//&[colspan]:not([colspan="1"]) {
//background: $gray-lighter;
//font-weight: $font-weight-heavy;
//}
//}
//td{
//text-align: right;
//}
//}
//tfoot{
//tr{
//th{
//background-color: $gray-lighter;
//}
//td{
//background-color: $gray-super-light;
//}
//}
//}
//}
//  }
//}


//=======================================================================================================
// PFEI Homepage "Table"
//=======================================================================================================
  .table-pfei{
      .btn{
        margin: 2px;
        &.btn-primary{
          a{
         color: $white;
          }
        }
      }
    .view-content{
      display: table;
      width: 100%;
      margin-bottom: 15px;
      .views-row{
        display: table-row;
        .views-field{
          display: table-cell;
          white-space: nowrap;
        }
        .views-field{
          border-bottom: solid 1px $gray-light-table;
          &:not(.views-field-view-node){
            //padding: 6px;
          }
        }
        &:last-of-type{
            .views-field{
              border-bottom: none;
            }
          }
        .views-field-field-product{
          //width: 50%;
          text-align: left;
        }
        .views-field-field-key-statistics{
          .field-content{
            .field--name-title{
              text-align: center;
              float: left;
              width: 50%;
            }
            .field--name-field-value{
              text-align: right;
               float: left;
              width: 50%;
            }
          }          
        }

      }
    }
  }
  
