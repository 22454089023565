/* debug=off 
* Article styling.
*/

.page-node-type-article {
	.form {
		.form-item-created-min,
			.form-item-created-max {
				display: none;
			}
		}
	}
//Formatting Blog article list
//.view-article-list{
//.views-row{
//margin-bottom: 30px;
//}
//h3 a{
////color: $gray-dark;
//font-size: $font-size-h2;
//font-weight: normal;
//margin-left: 0px;
//}
//p:not(.date){
//font-size: $font-size-large;
//}
//.date{
////padding: 3px 6px;
////background-color: $gray-lighter;
//}
//.list-inline{
//text-align: right;
//}
//}