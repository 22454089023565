/* debug=off 
* $file
* Contact component
*/
.contact{
  h4 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .fa {
    padding-right: 8px;
  }
}
//Call to Action Bar formatting
.connected-bar{
  text-align: center;
  .btn-default{
    color: #4F4F4F;
  }
  .btn-toolbar-centered{
    text-align: center;
    .btn-group{
      float: none !important;
    }
  }
}
