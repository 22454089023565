/* debug=off 
* Chart styling.
*/
.chart {
  .img-responsive {
    margin: 0 auto;
  }
  .user-logged-in &{
    h4 {
      display: none;
    }
    h5 {
      display: none;
    }
  }
}
.meta {
  color: darken($gray-light, 2%);
  font-size: 10px;
  margin: 5px 0;
}
//Styling for chart within homepage feature
.view-home-page-feature{
  .chart{
    img{
      height:auto;
      width: auto;
    }
  }
}
//Styling for Charts embedded using the Basic Page wysiwyg.
.page-node-type-page {
  .chart {
    &.embedded {
      clear: both;
      float: inherit;
      max-width: 600px;
      h4, h5, .meta {
        clear: both;
        text-align: center;
        width: 100%;
      }
    }
  }
}
//Styling for Charts in News Releases
.page-node-type-release {
  //Center IMG within embedded-entity types
  .embedded-entity, .center_div{
    text-align: center;
    width: 100% !important;
  }
  img{
    //&[data-entity-type="file"],
    //&[typeof="foaf:Image"]{
      height: auto;
      margin: 5vw auto;
      //border: solid 1px red;
      //width: 80%;
      @media (max-width: $screen-xs-min) {
        width: 100%;
        margin: 6vw auto;
      } 
      @media (min-width: $screen-sm-min) {
        width: 90%;
        margin: 5vw auto;
      } 
      @media (min-width: $screen-md-min) {
        width: 80%;
        margin: 4vw auto;
      } 
      @media (min-width: $screen-lg-min) {
        width: 70%;
        margin: 3vw auto;
      } 
    //}
  }
}
//image formatting in article pages (ie Blog post)
.article-full{
  img{
    width: 80%;
    padding: 25px;
    float: none;
    clear: both;
  }
}
//Teaser article formatting
.blog-list-page{
  .blog-list-item{
    .blog-post-content{
      img{
        width: 25%;
        height: auto;
        float: left;
        margin-right: 10px;
      }
    }    
  }
}
