.admin-docs{
  h2{
    span{
      font-weight: 200;
    }
  }
  h3{
    font-size: 120% !important;
  }
  a.img-hover:hover img{
    box-shadow: 5px 5px 44px -8px rgb(220, 96, 38);
    border-color: rgb(220, 96, 38);
  }
  .img-outline{
    padding: 10px;
    //border: solid 1px rgba(0,0,0,0.12);
    border-radius: 5px;
    //-webkit-box-shadow: 5px 5px 12px -1px rgba(0,0,0,0.35);
    //-moz-box-shadow: 5px 5px 12px -1px rgba(0,0,0,0.35);
    //box-shadow: 5px 5px 12px -1px rgba(0,0,0,0.35);
  }
  .col-deliniate-right, .col-deliniate-left{
    border-color: rgba(0, 0, 0, 0.25) !important;
  }
  .same-height .col-sm-4{
    height: 150px;
    padding-bottom: 20px;
  }
}