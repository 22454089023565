/* debug=off  */
//Dividing elements
.divider-line, hr {
  border: none;
  height: 1px;
  margin: 30px 0px;
  background-color: $brand-orange;
}
.divider-line.strip-it, hr.strip-it {
  margin: 5px 0px 5px 0px;
}
.path-frontpage{
  .divider-line, hr {
    margin: 40px 0px;     
  } 
}