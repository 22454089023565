/* debug=on 
 * Author content type and related components styling.
 */
.page-node-type-paper {
  h1 {
    &.page-header {
      display: none;
      position: absolute;
    }
  }
}

.page-node-type-paper, .paper-listing {
  p {
    &.card-author {
      text-align: right;
    }
    &.card-published {
      text-align: right;
      .modified {
        border-right: solid 1px rgba(0, 0, 0, 0.5);
        padding-right: 6px;
        margin-right: 8px;
      }
      .published {
        text-align: right;
      }
      .modified, .published {
        span {
          color: rgba(0, 0, 0, 0.5);
          margin-right: 8px;
          font-size: 80%;
        }
      }
    }
    &.card-type {
      margin-bottom: 8px;
    }
  }
  h2 {
    &.card-title {
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);
      font-size: 130%;
      margin-bottom: 4px;
      margin-top: 0;
      padding-bottom: 6px;
//      span:not(.file-type):not(.mime-type) {
//        color: rgba(0, 0, 0, 0.25);
//        float: right;
//        font-size: 70%;
//        font-weight: normal;
//        position: relative;
//        top: 8px;
//      }
    }
  }
  .card-abstract {
    margin-bottom: 8px;
    margin-top: 8px;
  }
}

/* Document Card =================================================== */
.page-node-type-author {
  .view-content, .view-empty {
    margin-top: 20px;
    .card-document {
      &.panel{
	    box-shadow: none;
		  border: 0;
		  }
      .card-details {
        p, h2 {
          margin: 0;
          padding: 0;
        }
        .card-type {
          margin-bottom: 8px;
          color: $gray;
        }
        h2.card-title {
          border-bottom: solid 1px rgba(0, 0, 0, 0.1);
          font-size: 130%;
          margin-bottom: 4px;
          padding-bottom: 6px;
//          span:not(.file-type):not(.mime-type) {
//            color: rgba(0, 0, 0, 0.25);
//            //float: right;
//            font-size: 70%;
//            font-weight: normal;
//            position: relative;
//            top: 8px;
//            @media #{$mobile-xs} {
//              float: none;
//              top: auto;
//              width: 100%;
//            }
//          }
        }
        .card-abstract {
          margin-bottom: 8px;
          margin-top: 8px;
        }
        .card-published {
          text-align: right;
          span, .field--name-field-publication-date {
            display: inline-block;
          }
          .paper-id{
            color: $gray;
          }
          .modified {
            border-right: solid 1px rgba(0, 0, 0, 0.5);
            margin-right: 8px;
            padding-right: 8px;
          }
          .periodical {
            font-style: italic;
            margin-left: 8px;
          }
          .modified, .published {
            span {
              color: rgba(0, 0, 0, 0.5);
              font-size: 80%;
              margin-right: 8px;
            }
          }
        }
        .card-author {
          clear: both;
          text-align: right;
          .field--name-field-author-reference {
            display: inline-block;
          }
        }
      }
      .card-supplements {
        margin-bottom: 20px;
        margin-left: 0;
      }
      .title {
        color: rgba(0, 0, 0, 0.5);
        list-style-image: none;
        list-style-type: none;
        margin-left: -15px;
        padding-bottom: 4px;
      }
      &.collapsed {
        border: 0px;
        background-color: transparent;
        @media #{$mobile-xs} {
          border: solid 1px $card-inner-border-light;
        }
    }
    }
  }
}
//Researcher page
.card-researcher{
	.card-details{
		.card-name{
			color: $headings-color;
		}
	}
}

/* Main Papers view page '/papers' =================================================== */
.path-papers {
  h1 {
    &.page-header {
      margin-top: 20px;
    }
  }
}

.view-papers {
  &.view-id-papers {
    .col-sm-12 {
      padding-left: 0;
      padding-right: 0;
    }
    .col-sm-9 {
      .row {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
    .col-sm-3 {
      padding-right: 0;
    }
    .form-group {
      label, div.col-sm-10 {
        display: inline-block;
      }
    }
  }
}
