/* debug=on  
 * Navbar styling.
 */
//=========================================================================//
//Offset fix for anchor tags due to fixed-to-top navbar.  height/margin is height of navbar plus any padding.
:target:before {
        content:"";
        display:block;
        height:53px; /* fixed header height*/
        margin:-53px 0 0; /* negative fixed header height */
}

//=========================================================================//
.navbar.container {
        @media #{$tablet} {
                max-width: (($container-sm - $grid-gutter-width));
        }
        @media #{$normal} {
                max-width: (($container-md - $grid-gutter-width));
        }
        @media #{$wide} {
                max-width: (($container-lg - $grid-gutter-width));
        }
}

//=========================================================================//
//Override Bootstrap and allow hamburger menu to expand past search box
.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    max-height: 100%;
}
//=========================================================================//
//enforce weight for hover
body .navbar-nav {
        > li a {
                -webkit-font-smoothing: antialiased;
        }
}

//=========================================================================//
//Admin tweak for shrinking banner comepnsation
body.user-logged-in.path-frontpage{
        padding-top: 290px !important;
}
//=========================================================================//
//Navbar secondary menu item drop downs
.dropdown:hover .dropdown-menu {
        display: block;
}

.dropdown-menu {
        font-size: $font-size-med-sm;
        margin-top: 0;
        border-top: solid 3px $brand-orange;
}

.dropdown-menu > li > a:hover {
        //color: $dropdown-link-hover-color;
        //background-color: $dropdown-link-hover-bg;
}

//Navbar toggle
.navbar-default {
        border-bottom: solid 3px $brand-orange;
        .navbar-toggle {
                margin-right: 15px;
                background-color: transparent;
                background-image: none;
                border: none; //border: 1px solid $white;
                border-radius: 4px;
                .icon-bar{
                        background-color:$white-opacity-75;
                }                        
                &.collapsed{
                        .icon-bar{
                                background-color:$white-opacity-75;
                        }                        
                }
                &:hover{
                        background-color: $white-opacity-75;
                        .icon-bar{
                                background-color:$brand-primary;
                        }
                }
        }
}


body{
        &.navbar-is-static-top {
                margin-top: 0;
        }
        &.navbar-is-fixed-top {
                //margin-top: ($navbar-height + ($grid-gutter-width / 2));
                margin-top: $navbar-height + $govbar-height;
        }
        &.navbar-is-fixed-bottom {
                padding-bottom: ($navbar-height + ($grid-gutter-width / 2));
        }
}
//=========================================================================//
//Top-Nav formatting on all pages except Homepage
body:not(.path-frontpage) {
        //padding-top: 73px;
        //Fix horizontal scrolling on iOS devices.
        //http://drupal.org/node/1870076
        position: relative;
        //=========================================================================//
  //Tweak to keep navbar form from jumping down on IE w/narrower screens. 
        //@media (min-width: 992px){
                //.navbar-form {
                //margin-left: -25px;
                //}
        //}
        
//@media #{$tablet} {
//margin-top: ($grid-gutter-width / 2);
//&.toolbar-vertical {
//&.navbar-is-fixed-top,
//&.navbar-is-fixed-bottom {
//.toolbar-bar {
//position: fixed;
//}
//header {
//z-index: 500;
//}
//}
////Default toolbar fixed height value.
////@see core/modules/toolbar/css/toolbar.icons.theme.css@261
//&.navbar-is-fixed-top header {
//top: 39px;
//}
//}
//}
//@media #{$mobile} {
//&.toolbar-vertical {
//&.navbar-is-fixed-top,
//&.navbar-is-fixed-bottom {
//.toolbar-bar {
//position: fixed;
//}
//header {
//z-index: 500;
//}
//}
////Default toolbar fixed height value.
////@see core/modules/toolbar/css/toolbar.icons.theme.css@261
//&.navbar-is-fixed-top header {
//top: 39px;
//}
//}
//}
        @medai#{$wide}{
                .navbar{
                        //background-color: aqua;
                }
        }

        @media #{$normal} {
                .navbar{
                        //background-color: $brand-primary;
                }
                a.navbar-brand {
                        float: left;
                        padding: 7px 15px 15px 12px;
                        font-size: 18px;
                        line-height: 20px;
                        width: 97px;
                        height: 50px;
                        margin: 0px 5px;
                        background-image: $nav-logo-svg-mobile;
                        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
                           /* IE10+ specific styles go here */  
                          background-image: $nav-logo-mobile;
                        }
                  
                        background-position: center 7px;
                        background-size: contain;
                        background-repeat: no-repeat;
                }
                 .navbar-form {
                        margin-left: -25px;
                }
        } 
        @media #{$tablet}{
                .navbar{
                               ////background-color:blueviolet;
                }
        }        
        @media #{$sub-tablet}{
                .navbar{
                        //background-color: green;
                        min-height: 50px;
                        .navbar-header {
                                width: auto;
                                text-align: center;
                                a.navbar-brand {
                                        float: left;
                                        padding: 0px;
                                        font-size: 18px;
                                        line-height: 20px;
                                        background-size: contain;
                                        background-repeat: no-repeat;
                                        width: 300px;
                                        height: 45px;
                                        margin: 0px 5px;
                        background-image: $nav-logo-svg-desktop;
                        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
                           /* IE10+ specific styles go here */  
                          background-image: $nav-logo-desktop;
                        }

                                        background-position: center center;
                                }
                                .navbar-toggle {
                                        position: relative;
                                        float: right;
                                        margin: 8px;
                                        padding: 9px 10px;
                                }

                                .region-navigation {
                                        float: left;
                                        vertical-align: bottom;
                                }
                        }
                }
.dropdown-menu {
    display: none !important;
}

.open .dropdown-menu {
    display: block !important;
}                
        } 
        @media #{$mobile} {
                .navbar{
                //background-color: yellow;

                }
        }
        @media #{$mobile-xs} {
                .navbar{
                //background-color: red;
                }
        }

}

//=========================================================================//
//Top-Nav formatting on ONLY Homepage
//Homepage shrinking header
body.path-frontpage{
        .in {
                //-webkit-transition: all 0.4s ease;
                //transition: all 0.4s ease;
        }
        .navbar {
                //-webkit-transition: all 0.4s ease;
                //transition: all 0.4s ease;
        }
        @medai#{$wide}{
                .navbar{
                        //background-color: aqua;
                }
        }
        @media #{$normal} {
                padding-top: ($navbar-home-height - $navbar-height - $govbar-height);
                .navbar {
                        //background-color: $brand-primary;
                        min-height: 223px;
                        .navbar-header {
                                width: auto;
                                .region-navigation {
                                        float: left;
                                        vertical-align: bottom;
                                }
                                .navbar-form {
                                        position: relative;
                                        top: -85px;
                                        //-webkit-transition: all 0.4s ease;
                                        //transition: all 0.4s ease;
                                        input.form-control {
                                                width: 350px !important;
                                                //-webkit-transition: all 0.4s ease;
                                                //transition: all 0.4s ease;
                                        }        
                                } 
                                a.navbar-brand {
                                        float: left;
                                        padding: 0px;
                                        margin: 10px;
                                        font-size: 18px;
                                        line-height: 20px;
                        background-image: $nav-logo-svg-desktop;
                        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
                           /* IE10+ specific styles go here */  
                          background-image: $nav-logo-desktop;
                        }
                                        width: 800px;
                                        height: 125px;
                                        background-size: contain;
                                        background-position: center left;
                                        background-repeat: no-repeat;
                                        //-webkit-transition: all 0.4s ease;
                                        //transition: all 0.4s ease;      
                                }    
                        }
                }
                .shrink{
                        min-height: 53px;
                        .navbar-header {
                                a.navbar-brand {
                                        width: 89px;
                                        height: 32px;
                                        margin: 8px 6px 8px 0px;
                        background-image: $nav-logo-svg-mobile;
                        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
                           /* IE10+ specific styles go here */  
                          background-image: $nav-logo-mobile;
                        }
                                        background-position: center center;
                                }
                        }
                        .navbar-toggle {
                                padding: 4px 5px;
                                margin: 8px 15px 8px 0;
                        }
                        .navbar-form {
                                //position: relative;
                                top: 0px;
                                padding: 0px;
                                //-webkit-transition: all 0.4s ease;
                                //transition: all 0.4s ease;
                                input.form-control {
                                        width: auto;
                                        //-webkit-transition: all 0.4s ease;
                                        //transition: all 0.4s ease;
                                }
                        }        
                }
        }
        @media #{$tablet}{
                .navbar{
                        //background-color:blueviolet;
                }
        }
        @media #{$sub-tablet}{
                //padding-top: 73px;
                .navbar{
                        //background-color: green;
                        min-height: 73px;
                        .navbar-header {
                                width: auto;
                                text-align: center;
                                a.navbar-brand {
                                        float: left;
                                        padding: 0px;
                                        font-size: 18px;
                                        line-height: 20px;
                                        background-size: contain;
                                        background-repeat: no-repeat;
                                        width: 300px;
                                        height: 45px;
                                        margin: 0px 5px;
                        background-image: $nav-logo-svg-desktop;
                        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
                           /* IE10+ specific styles go here */  
                          background-image: $nav-logo-desktop;
                        }

                                        background-position: center center;
                                }
                                .navbar-toggle {
                                        position: relative;
                                        float: right;
                                        margin: 8px;
                                        padding: 9px 10px;
                                }

                                .region-navigation {
                                        float: left;
                                        vertical-align: bottom;
                                }
                        }
                }
        } 
        @media #{$mobile} {
                .navbar{
                //background-color: yellow;

                }
        }
        @media #{$mobile-xs} {
                .navbar{
                //background-color: red;
                }
        }
}
//=========================================================================//
//========================================================================//
//Override bootstrap colors
.navbar-default {
  //Dropdown menu items
  .navbar-nav {
    //Remove background color from open dropdown
    > .open > a {
      &,
      &:hover,
      &:focus {
        background-color: $navbar-default-link-hover-bg;
        color: $navbar-default-link-hover-color;
      }
    }

  }
}
//=========================================================================//
//========================================================================//
//Glossary Navigation
.nav-glossary{
  text-align: center;
}
