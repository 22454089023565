/* debug=off 
* Blog styling
*/
//Base styles 
//-------------------------
//.article-full{
.page-node-type-article{
  .date-published{
    margin-bottom: 20px;
    color: $gray-light;
  }
}
//List block
.blog-list-item{
  margin-bottom: 25px;
  h2{
    &.blog-post-title{
      margin-top: 0px;
      margin-bottom: 1em;
      margin-bottom: 0px;
      font-weight: $font-weight-normal;
      //float: left;
      //border-bottom: solid 1px $gray-lighter;
      a{
        font-size: $font-size-h3;
      }
    }
  }
  h3{
    font-weight: $font-weight-normal;
  }
  .blog-post-date, .date-published{
    font-size: $font-size-med-sm;
    color: $gray-light;
    padding-top: 0px;
    margin-bottom: 10px;
  }
  .blog-post-content{
    font-size: $font-size-base;
    //CHART FORMATTING - See _chart.scss
  }
}  